import * as React from "react";
import { Button, Modal } from "antd";
import { InputRecordsApi } from "../../client";
import { useContext } from "react";
import {
    GlobalFilterContext,
    IFilterState,
} from "../../shared/contexts/GlobalFilterContextProvider";
import { dictToCamel } from "../../helpers/dictHelpers";

interface IProps {
    filters: IFilterState;
}

export const InputRecordsRetryButton: React.FC<IProps> = (props) => {
    const filterContext = useContext(GlobalFilterContext);

    const retryAll = () => {
        console.log(filterContext.filters.inputRecords);

        Modal.confirm({
            title: "Are you sure you want to retry the records?",
            okText: "Yes",
            cancelText: "No",
            onOk: () =>
                new InputRecordsApi().retryInputRecord({
                    ...props.filters,
                    ...dictToCamel(filterContext.filters.inputRecords),
                }),
        });
    };
    return <Button onClick={() => retryAll()}>Retry all</Button>;
};
