import * as React from "react";
import { useEffect, useState } from "react";
import { Select } from "antd";

interface IProps {
    fetch: () => void;
}

export const AutoRefetchDropdown: React.FC<IProps> = (props) => {
    const [intervalValue, setIntervalValue] = useState<number | null>(null);

    const options: (number | null)[] = [null, 5, 10, 30, 60];

    useEffect(() => {
        if (intervalValue === null) {
            return;
        }

        const intervalId = setInterval(() => {
            props.fetch();
        }, intervalValue * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [intervalValue]);

    return (
        <Select<number | null>
            value={intervalValue || 0}
            onSelect={(newValue) => setIntervalValue(newValue || null)}
            options={options.map((option) => ({
                key: option,
                label: option ? `${option} seconds` : "No auto fetch",
                value: option || 0,
            }))}
        />
    );
};
