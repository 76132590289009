import * as React from "react";
import { InputRecordsApi } from "../../client";
import { BaseLayout } from "../../shared/components/BaseLayout";
import { Input, Space, Typography } from "antd";
import { CustomDatePicker } from "../../shared/components/CustomDatePicker";
import moment, { Moment } from "moment";
import { InputRecordTable } from "../components/InputRecordTable";
import { BACKEND_DATE_FORMAT } from "../../shared/constants";
import { AutoRefetchDropdown } from "../../shared/components/AutoRefetchDropdown";
import { RefetchButton } from "../../shared/components/RefetchButton";
import { useInputRecords } from "../hooks/useInputRecords";
import { InputRecordsRetryButton } from "../components/InputRecordsRetryButton";

interface IProps {}

export const InputRecordOverview: React.FC<IProps> = (props) => {
    const [dateRange, setDateRange] = React.useState<[Moment, Moment]>([
        moment().startOf("day"),
        moment().endOf("day"),
    ]);
    const [searchValue, setSearchValue] = React.useState("");

    const baseFilters = {
        createdAfter: dateRange[0].format(BACKEND_DATE_FORMAT),
        createdBefore: dateRange[1].format(BACKEND_DATE_FORMAT),
        all: searchValue,
    };

    const {
        models: inputRecords,
        loading,
        tableProps,
        fetch,
    } = useInputRecords(baseFilters, [dateRange]);

    return (
        <BaseLayout>
            <Typography.Title level={3}>Latest import files</Typography.Title>

            <div style={{ margin: "1em auto", display: "flex" }}>
                <Space>
                    <Input.Search
                        placeholder="search..."
                        loading={loading}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onSearch={() => fetch()}
                    />

                    <CustomDatePicker
                        value={dateRange}
                        onChange={(start, end) => setDateRange([start, end])}
                    />

                    <InputRecordsRetryButton filters={baseFilters} />

                    <AutoRefetchDropdown fetch={() => fetch()} />

                    <RefetchButton loading={loading} fetch={fetch} />
                </Space>
            </div>
            <InputRecordTable
                inputRecords={inputRecords}
                loading={loading}
                tableProps={tableProps}
                onDelete={(inputRecordId) =>
                    new InputRecordsApi()
                        .destroyInputRecord({ id: String(inputRecordId) })
                        .then(() => fetch())
                }
            />
        </BaseLayout>
    );
};
