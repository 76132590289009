export const toSnake = (s: string) => {
    return s
        .replace(/([a-z\d])([A-Z])/g, "$1_$2")
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1_$2")
        .toLowerCase();
};

export const dicToSnake = (dict: {
    [index: string]: any;
}): { [index: string]: any } => {
    const obj: { [key: string]: any } = {};
    Object.keys(dict).forEach((key: string) => {
        if (dict[key] !== undefined) {
            obj[toSnake(key)] = dict[key];
        }
    });

    return obj;
};

/***
 * Converts the snake case based string to camel case
 * @param input string which should be converted
 */
export const toCamel = (input: string): string =>
    input.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
    });

export const arrayToCamel = <
    V extends {
        [index: string]: any;
    }
>(
    arr: V[]
): V[] => {
    return arr.map((value) => {
        if (Array.isArray(value)) {
            return arrayToCamel(value);
        } else if (typeof value === "object") {
            return dictToCamel(value, true);
        } else {
            return value;
        }
    }) as V[];
};

/***
 * Converts the snake case based dict to camel case
 * @param dict dict that should be converted
 * @param deep Whether to deeply replace inner dicts
 */
export const dictToCamel = (
    dict: {
        [index: string]: any;
    },
    deep: boolean = false
): {
    [index: string]: any;
} => {
    return Object.fromEntries(
        Object.keys(dict).map((key) => {
            const value = dict[key];
            const camelKey = toCamel(key);
            if (deep && Array.isArray(value)) {
                return [camelKey, arrayToCamel(value)];
            } else if (deep && value && typeof value === "object") {
                return [camelKey, dictToCamel(value, true)];
            } else {
                return [camelKey, value];
            }
        })
    );
};
