import * as React from "react";
import { Modal, Table } from "antd";
import { useApiModels } from "../../shared/hooks/useApiModels";
import { InputConnectorLog, InputConnectorLogsApi } from "../../client";
import { Status } from "../../shared/components/Status";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../shared/constants";

interface IProps {
    inputConnectorId: number;
    onClose: () => void;
}

export const InputConnectorLogModal: React.FC<IProps> = (props) => {
    const {
        models: logs,
        loading,
        tableProps,
    } = useApiModels<InputConnectorLog>(
        (page) =>
            new InputConnectorLogsApi().listInputConnectorLogs({
                page,
                inputConnectorId: String(props.inputConnectorId),
            }),
        "generic"
    );

    return (
        <Modal
            open={true}
            title="Input connector logs"
            onCancel={() => props.onClose()}
            onOk={() => props.onClose()}
            width="1100px"
        >
            <Table<InputConnectorLog>
                dataSource={logs}
                {...tableProps}
                loading={loading}
                rowKey="id"
                columns={[
                    {
                        key: "id",
                        dataIndex: "id",
                        title: "#",
                    },
                    {
                        key: "created",
                        dataIndex: "created",
                        title: "Created",
                        render: (created) =>
                            moment(created).format(DATE_TIME_FORMAT),
                    },
                    {
                        key: "message",
                        dataIndex: "message",
                        title: "Message",
                    },
                    {
                        key: "success",
                        dataIndex: "success",
                        title: "Success",
                        render: (success) => <Status value={success} />,
                    },
                ]}
            />
        </Modal>
    );
};
