import * as React from "react";
import { useEffect, useState } from "react";
import { Divider, Modal, Spin, Steps, Typography } from "antd";
import { InputConnectorDocs, InputConnectorsApi } from "../../client";

interface IProps {
    inputConnectorId: number;
    onClose: () => void;
}

export const InputConnectorDocumentationModal: React.FC<IProps> = (props) => {
    const [data, setData] = useState<InputConnectorDocs | null>(null);

    useEffect(() => {
        new InputConnectorsApi()
            .docsInputConnectorDocs({
                id: props.inputConnectorId.toString(),
            })
            .then((res) => setData(res.data));
    }, [props.inputConnectorId]);

    return (
        <Modal
            open={true}
            title={
                data
                    ? `${data.title} documentation`
                    : "Input connector documentation"
            }
            onCancel={() => props.onClose()}
            onOk={() => props.onClose()}
            width="1100px"
        >
            <Spin spinning={!data}>
                {data && (
                    <>
                        <Typography.Paragraph>
                            {data.description}
                        </Typography.Paragraph>

                        <Divider />

                        <Steps
                            direction="vertical"
                            current={-1}
                            items={data.steps.map((step) => ({ title: step }))}
                        />
                    </>
                )}
            </Spin>
        </Modal>
    );
};
