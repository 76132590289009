/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { InputConnector } from '../models';
// @ts-ignore
import { InputConnectorDocs } from '../models';
// @ts-ignore
import { InputConnectorUpload } from '../models';
// @ts-ignore
import { ListInputConnectors200Response } from '../models';
// @ts-ignore
import { PartialInputConnector } from '../models';
/**
 * InputConnectorsApi - axios parameter creator
 * @export
 */
export const InputConnectorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputConnector: async (inputConnector?: InputConnector, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_connectors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputConnector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputConnector: async (id: string, ids?: string, active?: string, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyInputConnector', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the documentation for the input connector.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsInputConnectorDocs: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('docsInputConnectorDocs', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/docs/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generic email webhook endpoint for all emails for all tenants.
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailInputConnector: async (inputConnector?: InputConnector, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_connectors/email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputConnector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputConnectors: async (page?: number, ids?: string, active?: string, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_connectors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {PartialInputConnector} [partialInputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputConnector: async (id: string, ids?: string, active?: string, type?: string, partialInputConnector?: PartialInputConnector, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateInputConnector', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialInputConnector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputConnector: async (id: string, ids?: string, active?: string, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveInputConnector', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testInputConnector: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testInputConnector', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/test/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Manually trigger file collection for the input connector.
         * @param {string} id 
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerInputConnector: async (id: string, inputConnector?: InputConnector, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('triggerInputConnector', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/trigger/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputConnector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputConnector: async (id: string, ids?: string, active?: string, type?: string, inputConnector?: InputConnector, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInputConnector', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputConnector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Manual upload file for the input connector.
         * @param {string} id 
         * @param {InputConnectorUpload} [inputConnectorUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInputConnectorUpload: async (id: string, inputConnectorUpload?: InputConnectorUpload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadInputConnectorUpload', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/upload/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputConnectorUpload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generic webhook endpoint for all json requests for all tenants.
         * @param {string} id 
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookInputConnector: async (id: string, inputConnector?: InputConnector, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webhookInputConnector', 'id', id)
            const localVarPath = `/api/input_connectors/{id}/webhook/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputConnector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InputConnectorsApi - functional programming interface
 * @export
 */
export const InputConnectorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InputConnectorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInputConnector(inputConnector?: InputConnector, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInputConnector(inputConnector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.createInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyInputConnector(id: string, ids?: string, active?: string, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyInputConnector(id, ids, active, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.destroyInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the documentation for the input connector.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsInputConnectorDocs(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnectorDocs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsInputConnectorDocs(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.docsInputConnectorDocs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generic email webhook endpoint for all emails for all tenants.
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailInputConnector(inputConnector?: InputConnector, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailInputConnector(inputConnector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.emailInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInputConnectors(page?: number, ids?: string, active?: string, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInputConnectors200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInputConnectors(page, ids, active, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.listInputConnectors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {PartialInputConnector} [partialInputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateInputConnector(id: string, ids?: string, active?: string, type?: string, partialInputConnector?: PartialInputConnector, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialInputConnector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateInputConnector(id, ids, active, type, partialInputConnector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.partialUpdateInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveInputConnector(id: string, ids?: string, active?: string, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveInputConnector(id, ids, active, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.retrieveInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testInputConnector(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testInputConnector(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.testInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Manually trigger file collection for the input connector.
         * @param {string} id 
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerInputConnector(id: string, inputConnector?: InputConnector, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerInputConnector(id, inputConnector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.triggerInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [ids] ids
         * @param {string} [active] active
         * @param {string} [type] type
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInputConnector(id: string, ids?: string, active?: string, type?: string, inputConnector?: InputConnector, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInputConnector(id, ids, active, type, inputConnector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.updateInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Manual upload file for the input connector.
         * @param {string} id 
         * @param {InputConnectorUpload} [inputConnectorUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInputConnectorUpload(id: string, inputConnectorUpload?: InputConnectorUpload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnectorUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInputConnectorUpload(id, inputConnectorUpload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.uploadInputConnectorUpload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generic webhook endpoint for all json requests for all tenants.
         * @param {string} id 
         * @param {InputConnector} [inputConnector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookInputConnector(id: string, inputConnector?: InputConnector, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookInputConnector(id, inputConnector, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorsApi.webhookInputConnector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InputConnectorsApi - factory interface
 * @export
 */
export const InputConnectorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InputConnectorsApiFp(configuration)
    return {
        /**
         * 
         * @param {InputConnectorsApiCreateInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputConnector(requestParameters: InputConnectorsApiCreateInputConnectorRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InputConnector> {
            return localVarFp.createInputConnector(requestParameters.inputConnector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorsApiDestroyInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputConnector(requestParameters: InputConnectorsApiDestroyInputConnectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.destroyInputConnector(requestParameters.id, requestParameters.ids, requestParameters.active, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the documentation for the input connector.
         * @param {InputConnectorsApiDocsInputConnectorDocsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsInputConnectorDocs(requestParameters: InputConnectorsApiDocsInputConnectorDocsRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnectorDocs> {
            return localVarFp.docsInputConnectorDocs(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Generic email webhook endpoint for all emails for all tenants.
         * @param {InputConnectorsApiEmailInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailInputConnector(requestParameters: InputConnectorsApiEmailInputConnectorRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InputConnector> {
            return localVarFp.emailInputConnector(requestParameters.inputConnector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorsApiListInputConnectorsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputConnectors(requestParameters: InputConnectorsApiListInputConnectorsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInputConnectors200Response> {
            return localVarFp.listInputConnectors(requestParameters.page, requestParameters.ids, requestParameters.active, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorsApiPartialUpdateInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputConnector(requestParameters: InputConnectorsApiPartialUpdateInputConnectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartialInputConnector> {
            return localVarFp.partialUpdateInputConnector(requestParameters.id, requestParameters.ids, requestParameters.active, requestParameters.type, requestParameters.partialInputConnector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorsApiRetrieveInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputConnector(requestParameters: InputConnectorsApiRetrieveInputConnectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnector> {
            return localVarFp.retrieveInputConnector(requestParameters.id, requestParameters.ids, requestParameters.active, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorsApiTestInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testInputConnector(requestParameters: InputConnectorsApiTestInputConnectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnector> {
            return localVarFp.testInputConnector(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Manually trigger file collection for the input connector.
         * @param {InputConnectorsApiTriggerInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerInputConnector(requestParameters: InputConnectorsApiTriggerInputConnectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnector> {
            return localVarFp.triggerInputConnector(requestParameters.id, requestParameters.inputConnector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorsApiUpdateInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputConnector(requestParameters: InputConnectorsApiUpdateInputConnectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnector> {
            return localVarFp.updateInputConnector(requestParameters.id, requestParameters.ids, requestParameters.active, requestParameters.type, requestParameters.inputConnector, options).then((request) => request(axios, basePath));
        },
        /**
         * Manual upload file for the input connector.
         * @param {InputConnectorsApiUploadInputConnectorUploadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInputConnectorUpload(requestParameters: InputConnectorsApiUploadInputConnectorUploadRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnectorUpload> {
            return localVarFp.uploadInputConnectorUpload(requestParameters.id, requestParameters.inputConnectorUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * Generic webhook endpoint for all json requests for all tenants.
         * @param {InputConnectorsApiWebhookInputConnectorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookInputConnector(requestParameters: InputConnectorsApiWebhookInputConnectorRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnector> {
            return localVarFp.webhookInputConnector(requestParameters.id, requestParameters.inputConnector, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiCreateInputConnectorRequest
 */
export interface InputConnectorsApiCreateInputConnectorRequest {
    /**
     * 
     * @type {InputConnector}
     * @memberof InputConnectorsApiCreateInputConnector
     */
    readonly inputConnector?: InputConnector
}

/**
 * Request parameters for destroyInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiDestroyInputConnectorRequest
 */
export interface InputConnectorsApiDestroyInputConnectorRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiDestroyInputConnector
     */
    readonly id: string

    /**
     * ids
     * @type {string}
     * @memberof InputConnectorsApiDestroyInputConnector
     */
    readonly ids?: string

    /**
     * active
     * @type {string}
     * @memberof InputConnectorsApiDestroyInputConnector
     */
    readonly active?: string

    /**
     * type
     * @type {string}
     * @memberof InputConnectorsApiDestroyInputConnector
     */
    readonly type?: string
}

/**
 * Request parameters for docsInputConnectorDocs operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiDocsInputConnectorDocsRequest
 */
export interface InputConnectorsApiDocsInputConnectorDocsRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiDocsInputConnectorDocs
     */
    readonly id: string
}

/**
 * Request parameters for emailInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiEmailInputConnectorRequest
 */
export interface InputConnectorsApiEmailInputConnectorRequest {
    /**
     * 
     * @type {InputConnector}
     * @memberof InputConnectorsApiEmailInputConnector
     */
    readonly inputConnector?: InputConnector
}

/**
 * Request parameters for listInputConnectors operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiListInputConnectorsRequest
 */
export interface InputConnectorsApiListInputConnectorsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof InputConnectorsApiListInputConnectors
     */
    readonly page?: number

    /**
     * ids
     * @type {string}
     * @memberof InputConnectorsApiListInputConnectors
     */
    readonly ids?: string

    /**
     * active
     * @type {string}
     * @memberof InputConnectorsApiListInputConnectors
     */
    readonly active?: string

    /**
     * type
     * @type {string}
     * @memberof InputConnectorsApiListInputConnectors
     */
    readonly type?: string
}

/**
 * Request parameters for partialUpdateInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiPartialUpdateInputConnectorRequest
 */
export interface InputConnectorsApiPartialUpdateInputConnectorRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiPartialUpdateInputConnector
     */
    readonly id: string

    /**
     * ids
     * @type {string}
     * @memberof InputConnectorsApiPartialUpdateInputConnector
     */
    readonly ids?: string

    /**
     * active
     * @type {string}
     * @memberof InputConnectorsApiPartialUpdateInputConnector
     */
    readonly active?: string

    /**
     * type
     * @type {string}
     * @memberof InputConnectorsApiPartialUpdateInputConnector
     */
    readonly type?: string

    /**
     * 
     * @type {PartialInputConnector}
     * @memberof InputConnectorsApiPartialUpdateInputConnector
     */
    readonly partialInputConnector?: PartialInputConnector
}

/**
 * Request parameters for retrieveInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiRetrieveInputConnectorRequest
 */
export interface InputConnectorsApiRetrieveInputConnectorRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiRetrieveInputConnector
     */
    readonly id: string

    /**
     * ids
     * @type {string}
     * @memberof InputConnectorsApiRetrieveInputConnector
     */
    readonly ids?: string

    /**
     * active
     * @type {string}
     * @memberof InputConnectorsApiRetrieveInputConnector
     */
    readonly active?: string

    /**
     * type
     * @type {string}
     * @memberof InputConnectorsApiRetrieveInputConnector
     */
    readonly type?: string
}

/**
 * Request parameters for testInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiTestInputConnectorRequest
 */
export interface InputConnectorsApiTestInputConnectorRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiTestInputConnector
     */
    readonly id: string
}

/**
 * Request parameters for triggerInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiTriggerInputConnectorRequest
 */
export interface InputConnectorsApiTriggerInputConnectorRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiTriggerInputConnector
     */
    readonly id: string

    /**
     * 
     * @type {InputConnector}
     * @memberof InputConnectorsApiTriggerInputConnector
     */
    readonly inputConnector?: InputConnector
}

/**
 * Request parameters for updateInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiUpdateInputConnectorRequest
 */
export interface InputConnectorsApiUpdateInputConnectorRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiUpdateInputConnector
     */
    readonly id: string

    /**
     * ids
     * @type {string}
     * @memberof InputConnectorsApiUpdateInputConnector
     */
    readonly ids?: string

    /**
     * active
     * @type {string}
     * @memberof InputConnectorsApiUpdateInputConnector
     */
    readonly active?: string

    /**
     * type
     * @type {string}
     * @memberof InputConnectorsApiUpdateInputConnector
     */
    readonly type?: string

    /**
     * 
     * @type {InputConnector}
     * @memberof InputConnectorsApiUpdateInputConnector
     */
    readonly inputConnector?: InputConnector
}

/**
 * Request parameters for uploadInputConnectorUpload operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiUploadInputConnectorUploadRequest
 */
export interface InputConnectorsApiUploadInputConnectorUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiUploadInputConnectorUpload
     */
    readonly id: string

    /**
     * 
     * @type {InputConnectorUpload}
     * @memberof InputConnectorsApiUploadInputConnectorUpload
     */
    readonly inputConnectorUpload?: InputConnectorUpload
}

/**
 * Request parameters for webhookInputConnector operation in InputConnectorsApi.
 * @export
 * @interface InputConnectorsApiWebhookInputConnectorRequest
 */
export interface InputConnectorsApiWebhookInputConnectorRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorsApiWebhookInputConnector
     */
    readonly id: string

    /**
     * 
     * @type {InputConnector}
     * @memberof InputConnectorsApiWebhookInputConnector
     */
    readonly inputConnector?: InputConnector
}

/**
 * InputConnectorsApi - object-oriented interface
 * @export
 * @class InputConnectorsApi
 * @extends {BaseAPI}
 */
export class InputConnectorsApi extends BaseAPI {
    /**
     * 
     * @param {InputConnectorsApiCreateInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public createInputConnector(requestParameters: InputConnectorsApiCreateInputConnectorRequest = {}, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).createInputConnector(requestParameters.inputConnector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorsApiDestroyInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public destroyInputConnector(requestParameters: InputConnectorsApiDestroyInputConnectorRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).destroyInputConnector(requestParameters.id, requestParameters.ids, requestParameters.active, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the documentation for the input connector.
     * @param {InputConnectorsApiDocsInputConnectorDocsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public docsInputConnectorDocs(requestParameters: InputConnectorsApiDocsInputConnectorDocsRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).docsInputConnectorDocs(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generic email webhook endpoint for all emails for all tenants.
     * @param {InputConnectorsApiEmailInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public emailInputConnector(requestParameters: InputConnectorsApiEmailInputConnectorRequest = {}, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).emailInputConnector(requestParameters.inputConnector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorsApiListInputConnectorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public listInputConnectors(requestParameters: InputConnectorsApiListInputConnectorsRequest = {}, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).listInputConnectors(requestParameters.page, requestParameters.ids, requestParameters.active, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorsApiPartialUpdateInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public partialUpdateInputConnector(requestParameters: InputConnectorsApiPartialUpdateInputConnectorRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).partialUpdateInputConnector(requestParameters.id, requestParameters.ids, requestParameters.active, requestParameters.type, requestParameters.partialInputConnector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorsApiRetrieveInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public retrieveInputConnector(requestParameters: InputConnectorsApiRetrieveInputConnectorRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).retrieveInputConnector(requestParameters.id, requestParameters.ids, requestParameters.active, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorsApiTestInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public testInputConnector(requestParameters: InputConnectorsApiTestInputConnectorRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).testInputConnector(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Manually trigger file collection for the input connector.
     * @param {InputConnectorsApiTriggerInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public triggerInputConnector(requestParameters: InputConnectorsApiTriggerInputConnectorRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).triggerInputConnector(requestParameters.id, requestParameters.inputConnector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorsApiUpdateInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public updateInputConnector(requestParameters: InputConnectorsApiUpdateInputConnectorRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).updateInputConnector(requestParameters.id, requestParameters.ids, requestParameters.active, requestParameters.type, requestParameters.inputConnector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Manual upload file for the input connector.
     * @param {InputConnectorsApiUploadInputConnectorUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public uploadInputConnectorUpload(requestParameters: InputConnectorsApiUploadInputConnectorUploadRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).uploadInputConnectorUpload(requestParameters.id, requestParameters.inputConnectorUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generic webhook endpoint for all json requests for all tenants.
     * @param {InputConnectorsApiWebhookInputConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorsApi
     */
    public webhookInputConnector(requestParameters: InputConnectorsApiWebhookInputConnectorRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorsApiFp(this.configuration).webhookInputConnector(requestParameters.id, requestParameters.inputConnector, options).then((request) => request(this.axios, this.basePath));
    }
}

