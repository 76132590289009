import * as React from "react";
import { useContext } from "react";
import { Menu } from "antd";
import { AuthService } from "../auth/authService";
import { GlobalContext } from "../context/GlobalContextProvider";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {}

export const AuthenticatedMenu: React.FC<IProps> = (props) => {
    const globalContext = useContext(GlobalContext);
    const navigate = useNavigate();
    const location = useLocation();

    const items: Array<{
        key: string;
        label: string | undefined;
        onClick?: () => void;
    } | null> = [
        {
            key: "/",
            label: "Home",
            onClick: () => navigate(`/`),
        },
        {
            key: "/input",
            label: "Inputs",
            onClick: () => navigate(`/inputs`),
        },
        globalContext.current?.tenant
            ? null
            : {
                  key: "toAdmin",
                  label: "Ga naar admin",
                  onClick: () => (window.location.href = "/admin"),
              },
        {
            key: "user",
            label: globalContext.user?.username,
        },
        {
            key: "logout",
            label: "Uitloggen",
            onClick: () =>
                AuthService.logout().then(() => window.location.reload()),
        },
    ];

    const activeKey = items.find(
        (item) =>
            (item && location.pathname === item.key) ||
            (item && item.key !== "/" && location.pathname.startsWith(item.key))
    )?.key;

    return (
        <Menu
            mode="horizontal"
            theme="light"
            activeKey={activeKey}
            items={items}
        />
    );
};
