import { useEffectOnce } from "../../helpers/useEffectOnce";
import React, { useContext, useEffect } from "react";
import {
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
} from "antd/lib/table/interface";
import { PaginationProps } from "antd";
import { dictToCamel } from "../../helpers/dictHelpers";
import {
    FilterKey,
    GlobalFilterContext,
    IFilterState,
} from "../contexts/GlobalFilterContextProvider";

export interface ITableProps<RecordType extends object> {
    onChange: (
        pagination: PaginationProps,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
        extra: TableCurrentDataSource<RecordType>
    ) => void;
    pagination: {
        current: number;
        pageSize: number;
        showSizeChanger: boolean;
        showQuickJumper: boolean;
        total: number;
    };
}

export function useApiModels<T extends object>(
    func: (
        page: number,
        filters: IFilterState
    ) => Promise<{ data: { results: T[]; count: number } }>,
    filterKey: FilterKey,
    dependencies?: React.DependencyList
): {
    models: T[];
    loading: boolean;
    tableProps: ITableProps<T>;
    fetch: (page?: number, filters?: IFilterState) => void;
} {
    const [apiModels, setApiModels] = React.useState<T[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    const filterContext = useContext(GlobalFilterContext);

    const getFilters = (): IFilterState => filterContext.filters[filterKey];

    const fetch = (overridePage?: number, overrideFilters?: IFilterState) => {
        overridePage = overridePage || page;
        overrideFilters = dictToCamel(
            overrideFilters !== undefined ? overrideFilters : getFilters()
        );
        setLoading(true);

        func(overridePage, overrideFilters)
            .then((res) => {
                setApiModels(res.data.results || []);
                setTotal(res.data.count || 0);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const onChange = (
        pagination: PaginationProps,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<T> | SorterResult<T>[],
        extra: TableCurrentDataSource<T>
    ) => {
        const newPage = pagination.current;
        if (newPage) {
            setPage(newPage);
        }

        const newFilters: IFilterState = Object.entries(filters).reduce(
            (prev, [key, value]) => {
                if (value === null) {
                    return prev;
                }

                return { ...prev, [key]: value.map(String) };
            },
            {}
        );
        filterContext.setFilters(filterKey, newFilters);

        fetch(newPage, newFilters);
    };

    useEffectOnce(() => {
        filterContext.setFilters(filterKey, {});

        if (!dependencies || dependencies.length === 0) {
            fetch(1, {});
        }
    });

    useEffect(() => {
        if (!dependencies || dependencies.length === 0) {
            return;
        }

        fetch();
    }, dependencies);

    return {
        models: apiModels,
        loading,
        fetch,
        tableProps: {
            pagination: {
                current: page,
                pageSize: 25,
                showSizeChanger: false,
                showQuickJumper: true,
                total,
            },
            onChange,
        },
    };
}
