import * as React from "react";
import { Button, Form, Input, Layout } from "antd";
import { AuthService } from "../authService";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContextProvider";
import { AuthApi } from "../../client";

interface IProps {}

interface IForm {
    username: string;
    password: string;
}

export const LoginScreen: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const globalContext = React.useContext(GlobalContext);

    const logIn = async (values: IForm) => {
        const api = new AuthApi();
        api.createAuthToken({
            username: values.username,
            password: values.password,
        }).then(() =>
            AuthService.getCurrentUser().then((current) => {
                globalContext.setUser(current?.user || null);
                globalContext.setCurrent(current || null);
                navigate("/");
            })
        );
    };

    return (
        <Layout
            style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ maxWidth: "30em" }}>
                <Form<IForm>
                    name="login"
                    initialValues={{
                        username: "",
                        password: "",
                    }}
                    onFinish={logIn}
                >
                    <Form.Item label="Username" name="username">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Password" name="password">
                        <Input type="password" />
                    </Form.Item>

                    <Button type="primary" htmlType="submit">
                        Log in
                    </Button>
                </Form>
            </div>
        </Layout>
    );
};
