import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { LoginScreen } from "../auth/screens/LoginScreen";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";
import { InputConnectorOverview } from "../inputConnectors/screens/InputConnectorOverview";
import { InputConnectorDetail } from "../inputConnectors/screens/InputConnectorDetail";
import { InputRecordOverview } from "../inputRecords/screens/InputRecordOverview";
import { InputRecordDetail } from "../inputRecords/screens/InputRecordDetail";
import { NotificationOverview } from "../notifications/screens/NotificationOverview";
import { InputRecordGroupDetail } from "../inputRecordGroups/screens/InputRecordGroupDetail";
interface IProps {}

export const Router: React.FC<IProps> = (props) => {
    const globalContext = useContext(GlobalContext);
    return (
        <Routes>
            <Route path="/login" element={<LoginScreen />} />

            {/*Authenticated routes*/}
            {globalContext.user && (
                <>
                    <Route path="/" element={<InputRecordOverview />} />
                    <Route
                        path="input-records/:id"
                        element={<InputRecordDetail />}
                    />
                    <Route
                        path="/inputs"
                        element={<InputConnectorOverview />}
                    />
                    <Route
                        path="/inputs/:id"
                        element={<InputConnectorDetail />}
                    />
                    <Route
                        path="/groups/:id"
                        element={<InputRecordGroupDetail />}
                    />
                    <Route
                        path="/notifications"
                        element={<NotificationOverview />}
                    />
                </>
            )}
        </Routes>
    );
};
