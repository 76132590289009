import React from "react";
import { BaseLayout } from "../../shared/components/BaseLayout";
import {
    InputConnector,
    InputConnectorsApi,
    InputRecordsApi,
} from "../../client";
import { useParams } from "react-router-dom";
import { InputRecordTable } from "../../inputRecords/components/InputRecordTable";
import { Button, Input, Modal, Space, Typography } from "antd";
import { useApiModel } from "../../shared/hooks/useApiModel";
import { InputConnectorManualUploadModal } from "../components/InputConnectorManualUploadModal";
import { AutoRefetchDropdown } from "../../shared/components/AutoRefetchDropdown";
import { RefetchButton } from "../../shared/components/RefetchButton";
import { useInputRecords } from "../../inputRecords/hooks/useInputRecords";
import { InputRecordsRetryButton } from "../../inputRecords/components/InputRecordsRetryButton";

interface IProps {}

export const InputConnectorDetail: React.FC<IProps> = () => {
    const inputConnectorId = useParams<{ id: string }>().id;
    const [manualUpload, setManualUpload] =
        React.useState<InputConnector | null>(null);

    const [searchValue, setSearchValue] = React.useState("");

    const baseFilters = {
        inputConnectorId: inputConnectorId || "0",
        all: searchValue,
    };

    const {
        models: inputRecords,
        loading,
        tableProps,
        fetch,
    } = useInputRecords(baseFilters);

    const { model: inputConnector } = useApiModel<InputConnector>(
        inputConnectorId || null,
        (id) =>
            new InputConnectorsApi().retrieveInputConnector({
                id,
            })
    );

    const trigger = () => {
        Modal.confirm({
            title: "Are you sure?",
            onOk: () =>
                new InputConnectorsApi().triggerInputConnector({
                    id: inputConnectorId!,
                }),
        });
    };

    return (
        <BaseLayout>
            {manualUpload && inputConnectorId && (
                <InputConnectorManualUploadModal
                    inputConnectorId={inputConnectorId}
                    onClose={() => {
                        fetch();
                        setManualUpload(null);
                    }}
                />
            )}

            <Typography.Title level={3}>
                {inputConnector?.name}
            </Typography.Title>
            <div style={{ marginBottom: "1em" }}>
                <Space>
                    <Input.Search
                        placeholder="search..."
                        loading={loading}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onSearch={() => fetch()}
                    />

                    <Button onClick={() => setManualUpload(inputConnector!)}>
                        Manual upload
                    </Button>

                    <Button onClick={() => trigger()}>
                        Trigger file collection
                    </Button>

                    <InputRecordsRetryButton filters={baseFilters} />

                    <AutoRefetchDropdown fetch={fetch} />

                    <RefetchButton loading={loading} fetch={fetch} />
                </Space>
            </div>
            <InputRecordTable
                loading={loading}
                inputRecords={inputRecords}
                tableProps={tableProps}
                onDelete={(inputRecordId) =>
                    new InputRecordsApi()
                        .destroyInputRecord({
                            id: String(inputRecordId),
                        })
                        .then(() => fetch())
                }
            />
        </BaseLayout>
    );
};
