import { Layout } from "antd";
import React from "react";

interface IProps {
    children: React.ReactNode;
}

export const BaseLayout: React.FC<IProps> = (props) => {
    return (
        <Layout style={{ height: "calc(100% - 50px)", padding: "1em" }}>
            {props.children}
        </Layout>
    );
};
