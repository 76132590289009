import * as React from "react";
import { BaseLayout } from "../../shared/components/BaseLayout";
import { useParams } from "react-router-dom";
import { Button, Modal, Space, Table, Typography } from "antd";
import {
    InputRecordGroupsApi,
    InputRecordRowGroupExportEntriesInner,
    NotificationsApi,
} from "../../client";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../shared/constants";
import { Status } from "../../shared/components/Status";
import { useApiModel } from "../../shared/hooks/useApiModel";
import { RawDataModal } from "../../shared/components/RawDataModal";
import { useApiModels } from "../../shared/hooks/useApiModels";
import { NotificationTable } from "../../notifications/components/NotificationTable";
import { ExternalRequestsTable } from "../../externalRequests/components/ExternalRequestsTable";

interface IProps {}

export const InputRecordGroupDetail: React.FC<IProps> = (props) => {
    const groupId = useParams<{ id: string }>().id || null;
    const [rawPreview, setRawPreview] = React.useState(false);

    const {
        loading,
        model: group,
        fetch,
    } = useApiModel(groupId, (newGroupId) =>
        new InputRecordGroupsApi().retrieveInputRecordGroup({
            id: newGroupId,
        })
    );

    const {
        models: notifications,
        loading: notificationsLoading,
        tableProps: notificationsTableProps,
    } = useApiModels(
        (page) =>
            new NotificationsApi().listNotifications({
                page,
                inputRecordGroupId: groupId || "0",
            }),
        "generic"
    );

    const retryExport = () => {
        Modal.confirm({
            title: "Are you sure you want to export the group (again)?",
            onOk: () => {
                if (!groupId) {
                    return;
                }
                return new InputRecordGroupsApi()
                    .retryInputRecordGroup({ id: groupId })
                    .then(() => fetch());
            },
        });
    };

    return (
        <BaseLayout>
            {rawPreview && group && group.converted_data && (
                <RawDataModal
                    data={group.converted_data}
                    onClose={() => setRawPreview(false)}
                />
            )}

            <Typography.Title level={2}>
                {(group && group.id) || ""}
            </Typography.Title>

            <div style={{ marginBottom: "1em" }}>
                <Space>
                    {group && group.converted_data && (
                        <Button onClick={() => setRawPreview(true)}>
                            View export data
                        </Button>
                    )}

                    {group && group.converted_file && (
                        <Button
                            onClick={() =>
                                window.open(
                                    `/api/input_record_groups/${group.id}/download/`
                                )
                            }
                        >
                            Download output
                        </Button>
                    )}

                    <Button onClick={() => retryExport()}>Retry export</Button>
                </Space>
            </div>

            {groupId && <ExternalRequestsTable groupId={groupId} />}

            <Table<InputRecordRowGroupExportEntriesInner>
                dataSource={(group && group.export_entries) || []}
                loading={loading}
                rowKey="id"
                title={() => "Export entries"}
                pagination={false}
                columns={[
                    {
                        key: "retry_number",
                        dataIndex: "retry_number",
                        title: "#",
                        width: "75px",
                    },
                    {
                        key: "status",
                        dataIndex: "status",
                        title: "Status",
                        width: "100px",
                        render: (_, record) => (
                            <Status
                                value={
                                    record.status === "success"
                                        ? true
                                        : record.status === "failed"
                                        ? false
                                        : null
                                }
                            />
                        ),
                    },
                    {
                        key: "created",
                        dataIndex: "created",
                        title: "Export time",
                        width: "300px",
                        render: (created) =>
                            moment(created).format(DATE_TIME_FORMAT),
                    },
                    {
                        key: "message",
                        dataIndex: "message",
                        title: "Message",
                    },
                ]}
            />

            <div style={{ marginTop: "1em" }}>
                <NotificationTable
                    notifications={notifications}
                    loading={notificationsLoading}
                    tableProps={notificationsTableProps}
                />
            </div>
        </BaseLayout>
    );
};
