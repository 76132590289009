import * as React from "react";
import { IUser } from "../auth/models/User";
import { useState } from "react";
import { ICurrent } from "../auth/models/Current";

export interface IGlobalContext {
    user: IUser | null;
    setUser: (user: IUser | null) => void;
    userLoaded: boolean;

    current: ICurrent | null;
    setCurrent: (current: ICurrent | null) => void;
}

export const GlobalContext = React.createContext<IGlobalContext>({
    user: null,
    setUser: () => {},
    userLoaded: false,
    current: null,
    setCurrent: () => {},
});

export const GlobalContextProvider = (props: any) => {
    const [user, setUser] = useState<IUser | null | undefined>(undefined);
    const [current, setCurrent] = useState<ICurrent | null | undefined>(
        undefined
    );

    return (
        <GlobalContext.Provider
            value={{
                user: user || null,
                setUser,
                userLoaded: user !== undefined,
                current: current || null,
                setCurrent,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};
