import * as React from "react";
import { createContext } from "react";

const filterKeys = ["generic", "inputRecords"] as const;
export type FilterKey = typeof filterKeys[number];

export interface IFilterState {
    [key: string]: string | string[];
}

export interface IFilterContext {
    // eslint-disable-next-line no-unused-vars
    filters: { [key in FilterKey]: IFilterState };
    setFilters: (page: FilterKey, newFilters: IFilterState) => void;
}

export const getBaseFilters = (): IFilterContext["filters"] =>
    filterKeys.reduce(
        (prev, key) => ({
            ...prev,
            [key]: {},
        }),
        {}
    ) as IFilterContext["filters"];

export const GlobalFilterContext = createContext<IFilterContext>({
    filters: getBaseFilters(),
    setFilters: () => {},
});

export const GlobalFilterContextProvider: React.FC<{
    children: React.ReactNode;
}> = (props) => {
    const [internalFilters, setInternalFilters] = React.useState<
        IFilterContext["filters"]
    >(getBaseFilters());
    return (
        <GlobalFilterContext.Provider
            value={{
                filters: internalFilters,
                setFilters: (page, newFilters) =>
                    setInternalFilters((oldValue) => ({
                        ...oldValue,
                        [page]: newFilters,
                    })),
            }}
        >
            {props.children}
        </GlobalFilterContext.Provider>
    );
};
