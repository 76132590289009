/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ExternalRequest } from '../models';
// @ts-ignore
import { ListExternalRequests200Response } from '../models';
// @ts-ignore
import { PartialExternalRequest } from '../models';
/**
 * ExternalRequestsApi - axios parameter creator
 * @export
 */
export const ExternalRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ExternalRequest} [externalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalRequest: async (externalRequest?: ExternalRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/external_requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [groupId] group_id
         * @param {DestroyExternalRequestOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyExternalRequest: async (id: string, groupId?: string, ordering?: DestroyExternalRequestOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyExternalRequest', 'id', id)
            const localVarPath = `/api/external_requests/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [groupId] group_id
         * @param {ListExternalRequestsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExternalRequests: async (page?: number, groupId?: string, ordering?: ListExternalRequestsOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/external_requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [groupId] group_id
         * @param {PartialUpdateExternalRequestOrderingEnum} [ordering] Ordering
         * @param {PartialExternalRequest} [partialExternalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateExternalRequest: async (id: string, groupId?: string, ordering?: PartialUpdateExternalRequestOrderingEnum, partialExternalRequest?: PartialExternalRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateExternalRequest', 'id', id)
            const localVarPath = `/api/external_requests/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialExternalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [groupId] group_id
         * @param {RetrieveExternalRequestOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveExternalRequest: async (id: string, groupId?: string, ordering?: RetrieveExternalRequestOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveExternalRequest', 'id', id)
            const localVarPath = `/api/external_requests/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [groupId] group_id
         * @param {UpdateExternalRequestOrderingEnum} [ordering] Ordering
         * @param {ExternalRequest} [externalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalRequest: async (id: string, groupId?: string, ordering?: UpdateExternalRequestOrderingEnum, externalRequest?: ExternalRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExternalRequest', 'id', id)
            const localVarPath = `/api/external_requests/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (groupId !== undefined) {
                localVarQueryParameter['group_id'] = groupId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalRequestsApi - functional programming interface
 * @export
 */
export const ExternalRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ExternalRequest} [externalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExternalRequest(externalRequest?: ExternalRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExternalRequest(externalRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalRequestsApi.createExternalRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [groupId] group_id
         * @param {DestroyExternalRequestOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyExternalRequest(id: string, groupId?: string, ordering?: DestroyExternalRequestOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyExternalRequest(id, groupId, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalRequestsApi.destroyExternalRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [groupId] group_id
         * @param {ListExternalRequestsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExternalRequests(page?: number, groupId?: string, ordering?: ListExternalRequestsOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListExternalRequests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExternalRequests(page, groupId, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalRequestsApi.listExternalRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [groupId] group_id
         * @param {PartialUpdateExternalRequestOrderingEnum} [ordering] Ordering
         * @param {PartialExternalRequest} [partialExternalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateExternalRequest(id: string, groupId?: string, ordering?: PartialUpdateExternalRequestOrderingEnum, partialExternalRequest?: PartialExternalRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialExternalRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateExternalRequest(id, groupId, ordering, partialExternalRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalRequestsApi.partialUpdateExternalRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [groupId] group_id
         * @param {RetrieveExternalRequestOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveExternalRequest(id: string, groupId?: string, ordering?: RetrieveExternalRequestOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveExternalRequest(id, groupId, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalRequestsApi.retrieveExternalRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [groupId] group_id
         * @param {UpdateExternalRequestOrderingEnum} [ordering] Ordering
         * @param {ExternalRequest} [externalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalRequest(id: string, groupId?: string, ordering?: UpdateExternalRequestOrderingEnum, externalRequest?: ExternalRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalRequest(id, groupId, ordering, externalRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalRequestsApi.updateExternalRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExternalRequestsApi - factory interface
 * @export
 */
export const ExternalRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalRequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {ExternalRequestsApiCreateExternalRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalRequest(requestParameters: ExternalRequestsApiCreateExternalRequestRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ExternalRequest> {
            return localVarFp.createExternalRequest(requestParameters.externalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExternalRequestsApiDestroyExternalRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyExternalRequest(requestParameters: ExternalRequestsApiDestroyExternalRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.destroyExternalRequest(requestParameters.id, requestParameters.groupId, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExternalRequestsApiListExternalRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExternalRequests(requestParameters: ExternalRequestsApiListExternalRequestsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListExternalRequests200Response> {
            return localVarFp.listExternalRequests(requestParameters.page, requestParameters.groupId, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExternalRequestsApiPartialUpdateExternalRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateExternalRequest(requestParameters: ExternalRequestsApiPartialUpdateExternalRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartialExternalRequest> {
            return localVarFp.partialUpdateExternalRequest(requestParameters.id, requestParameters.groupId, requestParameters.ordering, requestParameters.partialExternalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExternalRequestsApiRetrieveExternalRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveExternalRequest(requestParameters: ExternalRequestsApiRetrieveExternalRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalRequest> {
            return localVarFp.retrieveExternalRequest(requestParameters.id, requestParameters.groupId, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExternalRequestsApiUpdateExternalRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalRequest(requestParameters: ExternalRequestsApiUpdateExternalRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExternalRequest> {
            return localVarFp.updateExternalRequest(requestParameters.id, requestParameters.groupId, requestParameters.ordering, requestParameters.externalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createExternalRequest operation in ExternalRequestsApi.
 * @export
 * @interface ExternalRequestsApiCreateExternalRequestRequest
 */
export interface ExternalRequestsApiCreateExternalRequestRequest {
    /**
     * 
     * @type {ExternalRequest}
     * @memberof ExternalRequestsApiCreateExternalRequest
     */
    readonly externalRequest?: ExternalRequest
}

/**
 * Request parameters for destroyExternalRequest operation in ExternalRequestsApi.
 * @export
 * @interface ExternalRequestsApiDestroyExternalRequestRequest
 */
export interface ExternalRequestsApiDestroyExternalRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalRequestsApiDestroyExternalRequest
     */
    readonly id: string

    /**
     * group_id
     * @type {string}
     * @memberof ExternalRequestsApiDestroyExternalRequest
     */
    readonly groupId?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof ExternalRequestsApiDestroyExternalRequest
     */
    readonly ordering?: DestroyExternalRequestOrderingEnum
}

/**
 * Request parameters for listExternalRequests operation in ExternalRequestsApi.
 * @export
 * @interface ExternalRequestsApiListExternalRequestsRequest
 */
export interface ExternalRequestsApiListExternalRequestsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ExternalRequestsApiListExternalRequests
     */
    readonly page?: number

    /**
     * group_id
     * @type {string}
     * @memberof ExternalRequestsApiListExternalRequests
     */
    readonly groupId?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof ExternalRequestsApiListExternalRequests
     */
    readonly ordering?: ListExternalRequestsOrderingEnum
}

/**
 * Request parameters for partialUpdateExternalRequest operation in ExternalRequestsApi.
 * @export
 * @interface ExternalRequestsApiPartialUpdateExternalRequestRequest
 */
export interface ExternalRequestsApiPartialUpdateExternalRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalRequestsApiPartialUpdateExternalRequest
     */
    readonly id: string

    /**
     * group_id
     * @type {string}
     * @memberof ExternalRequestsApiPartialUpdateExternalRequest
     */
    readonly groupId?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof ExternalRequestsApiPartialUpdateExternalRequest
     */
    readonly ordering?: PartialUpdateExternalRequestOrderingEnum

    /**
     * 
     * @type {PartialExternalRequest}
     * @memberof ExternalRequestsApiPartialUpdateExternalRequest
     */
    readonly partialExternalRequest?: PartialExternalRequest
}

/**
 * Request parameters for retrieveExternalRequest operation in ExternalRequestsApi.
 * @export
 * @interface ExternalRequestsApiRetrieveExternalRequestRequest
 */
export interface ExternalRequestsApiRetrieveExternalRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalRequestsApiRetrieveExternalRequest
     */
    readonly id: string

    /**
     * group_id
     * @type {string}
     * @memberof ExternalRequestsApiRetrieveExternalRequest
     */
    readonly groupId?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof ExternalRequestsApiRetrieveExternalRequest
     */
    readonly ordering?: RetrieveExternalRequestOrderingEnum
}

/**
 * Request parameters for updateExternalRequest operation in ExternalRequestsApi.
 * @export
 * @interface ExternalRequestsApiUpdateExternalRequestRequest
 */
export interface ExternalRequestsApiUpdateExternalRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalRequestsApiUpdateExternalRequest
     */
    readonly id: string

    /**
     * group_id
     * @type {string}
     * @memberof ExternalRequestsApiUpdateExternalRequest
     */
    readonly groupId?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof ExternalRequestsApiUpdateExternalRequest
     */
    readonly ordering?: UpdateExternalRequestOrderingEnum

    /**
     * 
     * @type {ExternalRequest}
     * @memberof ExternalRequestsApiUpdateExternalRequest
     */
    readonly externalRequest?: ExternalRequest
}

/**
 * ExternalRequestsApi - object-oriented interface
 * @export
 * @class ExternalRequestsApi
 * @extends {BaseAPI}
 */
export class ExternalRequestsApi extends BaseAPI {
    /**
     * 
     * @param {ExternalRequestsApiCreateExternalRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalRequestsApi
     */
    public createExternalRequest(requestParameters: ExternalRequestsApiCreateExternalRequestRequest = {}, options?: RawAxiosRequestConfig) {
        return ExternalRequestsApiFp(this.configuration).createExternalRequest(requestParameters.externalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExternalRequestsApiDestroyExternalRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalRequestsApi
     */
    public destroyExternalRequest(requestParameters: ExternalRequestsApiDestroyExternalRequestRequest, options?: RawAxiosRequestConfig) {
        return ExternalRequestsApiFp(this.configuration).destroyExternalRequest(requestParameters.id, requestParameters.groupId, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExternalRequestsApiListExternalRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalRequestsApi
     */
    public listExternalRequests(requestParameters: ExternalRequestsApiListExternalRequestsRequest = {}, options?: RawAxiosRequestConfig) {
        return ExternalRequestsApiFp(this.configuration).listExternalRequests(requestParameters.page, requestParameters.groupId, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExternalRequestsApiPartialUpdateExternalRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalRequestsApi
     */
    public partialUpdateExternalRequest(requestParameters: ExternalRequestsApiPartialUpdateExternalRequestRequest, options?: RawAxiosRequestConfig) {
        return ExternalRequestsApiFp(this.configuration).partialUpdateExternalRequest(requestParameters.id, requestParameters.groupId, requestParameters.ordering, requestParameters.partialExternalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExternalRequestsApiRetrieveExternalRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalRequestsApi
     */
    public retrieveExternalRequest(requestParameters: ExternalRequestsApiRetrieveExternalRequestRequest, options?: RawAxiosRequestConfig) {
        return ExternalRequestsApiFp(this.configuration).retrieveExternalRequest(requestParameters.id, requestParameters.groupId, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExternalRequestsApiUpdateExternalRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalRequestsApi
     */
    public updateExternalRequest(requestParameters: ExternalRequestsApiUpdateExternalRequestRequest, options?: RawAxiosRequestConfig) {
        return ExternalRequestsApiFp(this.configuration).updateExternalRequest(requestParameters.id, requestParameters.groupId, requestParameters.ordering, requestParameters.externalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DestroyExternalRequestOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type DestroyExternalRequestOrderingEnum = typeof DestroyExternalRequestOrderingEnum[keyof typeof DestroyExternalRequestOrderingEnum];
/**
 * @export
 */
export const ListExternalRequestsOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type ListExternalRequestsOrderingEnum = typeof ListExternalRequestsOrderingEnum[keyof typeof ListExternalRequestsOrderingEnum];
/**
 * @export
 */
export const PartialUpdateExternalRequestOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type PartialUpdateExternalRequestOrderingEnum = typeof PartialUpdateExternalRequestOrderingEnum[keyof typeof PartialUpdateExternalRequestOrderingEnum];
/**
 * @export
 */
export const RetrieveExternalRequestOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type RetrieveExternalRequestOrderingEnum = typeof RetrieveExternalRequestOrderingEnum[keyof typeof RetrieveExternalRequestOrderingEnum];
/**
 * @export
 */
export const UpdateExternalRequestOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type UpdateExternalRequestOrderingEnum = typeof UpdateExternalRequestOrderingEnum[keyof typeof UpdateExternalRequestOrderingEnum];
