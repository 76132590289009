import * as React from "react";
import { ExternalRequest, ExternalRequestsApi } from "../../client";
import { useApiModels } from "../../shared/hooks/useApiModels";
import { Table } from "antd";
import moment from "moment/moment";
import { DATE_TIME_FORMAT } from "../../shared/constants";
import { Status } from "../../shared/components/Status";
import { RawDataPreview } from "../../shared/components/RawDataPreview";

interface IProps {
    groupId: string;
}

export const ExternalRequestsTable: React.FC<IProps> = (props) => {
    const { models, loading, tableProps } = useApiModels(
        (page) =>
            new ExternalRequestsApi().listExternalRequests({
                page,
                groupId: props.groupId,
                ordering: "-created",
            }),
        "generic"
    );

    const getBackground = (record: ExternalRequest) => {
        if (record.is_final) {
            if (record.success) {
                return "rgba(0, 128, 0, 0.6)";
            } else {
                return "rgba(255, 0, 0, 0.6)";
            }
        }
    };

    return (
        <Table<ExternalRequest>
            dataSource={models}
            loading={loading}
            rowKey="id"
            title={() => "External requests"}
            onRow={(record) => ({
                style: {
                    background: getBackground(record),
                },
            })}
            {...tableProps}
            columns={[
                {
                    key: "success",
                    dataIndex: "success",
                    title: "Success",
                    render: (value) => <Status value={value} />,
                    width: "100px",
                },
                {
                    key: "created",
                    dataIndex: "created",
                    title: "Created",
                    width: "300px",
                    render: (created) =>
                        moment(created).format(DATE_TIME_FORMAT),
                },
                {
                    key: "environment",
                    dataIndex: "environment",
                    title: "Envrionment",
                    render: (value, record) =>
                        value ||
                        new URL(record.url).protocol +
                            "//" +
                            new URL(record.url).host,
                },
                {
                    key: "url",
                    dataIndex: "url",
                    title: "Path",
                    render: (url: string) =>
                        new URL(url).pathname + new URL(url).search,
                },
                {
                    key: "action",
                    dataIndex: "action",
                    title: "Action",
                },
                {
                    key: "input_data",
                    dataIndex: "input_data",
                    title: "Input data",
                    ellipsis: true,
                    render: (data) => <RawDataPreview raw={data} />,
                },
                {
                    key: "output_data",
                    dataIndex: "output_data",
                    title: "Output data",
                    ellipsis: true,
                    render: (data) => <RawDataPreview raw={data} />,
                },
            ]}
        />
    );
};
