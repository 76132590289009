/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ListNotifications200Response } from '../models';
// @ts-ignore
import { Notification } from '../models';
// @ts-ignore
import { PartialNotification } from '../models';
/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: async (notification?: Notification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNotification: async (id: string, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyNotification', 'id', id)
            const localVarPath = `/api/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (inputRecordRowId !== undefined) {
                localVarQueryParameter['input_record_row_id'] = inputRecordRowId;
            }

            if (inputRecordGroupId !== undefined) {
                localVarQueryParameter['input_record_group_id'] = inputRecordGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications: async (page?: number, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (inputRecordRowId !== undefined) {
                localVarQueryParameter['input_record_row_id'] = inputRecordRowId;
            }

            if (inputRecordGroupId !== undefined) {
                localVarQueryParameter['input_record_group_id'] = inputRecordGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {PartialNotification} [partialNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateNotification: async (id: string, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, partialNotification?: PartialNotification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateNotification', 'id', id)
            const localVarPath = `/api/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (inputRecordRowId !== undefined) {
                localVarQueryParameter['input_record_row_id'] = inputRecordRowId;
            }

            if (inputRecordGroupId !== undefined) {
                localVarQueryParameter['input_record_group_id'] = inputRecordGroupId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNotification: async (id: string, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveNotification', 'id', id)
            const localVarPath = `/api/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (inputRecordRowId !== undefined) {
                localVarQueryParameter['input_record_row_id'] = inputRecordRowId;
            }

            if (inputRecordGroupId !== undefined) {
                localVarQueryParameter['input_record_group_id'] = inputRecordGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification: async (id: string, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, notification?: Notification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNotification', 'id', id)
            const localVarPath = `/api/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (inputRecordRowId !== undefined) {
                localVarQueryParameter['input_record_row_id'] = inputRecordRowId;
            }

            if (inputRecordGroupId !== undefined) {
                localVarQueryParameter['input_record_group_id'] = inputRecordGroupId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotification(notification?: Notification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotification(notification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.createNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyNotification(id: string, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyNotification(id, inputRecordId, inputRecordRowId, inputRecordGroupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.destroyNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotifications(page?: number, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNotifications(page, inputRecordId, inputRecordRowId, inputRecordGroupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.listNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {PartialNotification} [partialNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateNotification(id: string, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, partialNotification?: PartialNotification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialNotification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateNotification(id, inputRecordId, inputRecordRowId, inputRecordGroupId, partialNotification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.partialUpdateNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveNotification(id: string, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveNotification(id, inputRecordId, inputRecordRowId, inputRecordGroupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.retrieveNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [inputRecordRowId] input_record_row_id
         * @param {string} [inputRecordGroupId] input_record_group_id
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotification(id: string, inputRecordId?: string, inputRecordRowId?: string, inputRecordGroupId?: string, notification?: Notification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotification(id, inputRecordId, inputRecordRowId, inputRecordGroupId, notification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.updateNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {NotificationsApiCreateNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(requestParameters: NotificationsApiCreateNotificationRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.createNotification(requestParameters.notification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiDestroyNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNotification(requestParameters: NotificationsApiDestroyNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.destroyNotification(requestParameters.id, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiListNotificationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(requestParameters: NotificationsApiListNotificationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListNotifications200Response> {
            return localVarFp.listNotifications(requestParameters.page, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiPartialUpdateNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateNotification(requestParameters: NotificationsApiPartialUpdateNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartialNotification> {
            return localVarFp.partialUpdateNotification(requestParameters.id, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, requestParameters.partialNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiRetrieveNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveNotification(requestParameters: NotificationsApiRetrieveNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.retrieveNotification(requestParameters.id, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiUpdateNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification(requestParameters: NotificationsApiUpdateNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.updateNotification(requestParameters.id, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, requestParameters.notification, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiCreateNotificationRequest
 */
export interface NotificationsApiCreateNotificationRequest {
    /**
     * 
     * @type {Notification}
     * @memberof NotificationsApiCreateNotification
     */
    readonly notification?: Notification
}

/**
 * Request parameters for destroyNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiDestroyNotificationRequest
 */
export interface NotificationsApiDestroyNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiDestroyNotification
     */
    readonly id: string

    /**
     * input_record_id
     * @type {string}
     * @memberof NotificationsApiDestroyNotification
     */
    readonly inputRecordId?: string

    /**
     * input_record_row_id
     * @type {string}
     * @memberof NotificationsApiDestroyNotification
     */
    readonly inputRecordRowId?: string

    /**
     * input_record_group_id
     * @type {string}
     * @memberof NotificationsApiDestroyNotification
     */
    readonly inputRecordGroupId?: string
}

/**
 * Request parameters for listNotifications operation in NotificationsApi.
 * @export
 * @interface NotificationsApiListNotificationsRequest
 */
export interface NotificationsApiListNotificationsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof NotificationsApiListNotifications
     */
    readonly page?: number

    /**
     * input_record_id
     * @type {string}
     * @memberof NotificationsApiListNotifications
     */
    readonly inputRecordId?: string

    /**
     * input_record_row_id
     * @type {string}
     * @memberof NotificationsApiListNotifications
     */
    readonly inputRecordRowId?: string

    /**
     * input_record_group_id
     * @type {string}
     * @memberof NotificationsApiListNotifications
     */
    readonly inputRecordGroupId?: string
}

/**
 * Request parameters for partialUpdateNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiPartialUpdateNotificationRequest
 */
export interface NotificationsApiPartialUpdateNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiPartialUpdateNotification
     */
    readonly id: string

    /**
     * input_record_id
     * @type {string}
     * @memberof NotificationsApiPartialUpdateNotification
     */
    readonly inputRecordId?: string

    /**
     * input_record_row_id
     * @type {string}
     * @memberof NotificationsApiPartialUpdateNotification
     */
    readonly inputRecordRowId?: string

    /**
     * input_record_group_id
     * @type {string}
     * @memberof NotificationsApiPartialUpdateNotification
     */
    readonly inputRecordGroupId?: string

    /**
     * 
     * @type {PartialNotification}
     * @memberof NotificationsApiPartialUpdateNotification
     */
    readonly partialNotification?: PartialNotification
}

/**
 * Request parameters for retrieveNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiRetrieveNotificationRequest
 */
export interface NotificationsApiRetrieveNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiRetrieveNotification
     */
    readonly id: string

    /**
     * input_record_id
     * @type {string}
     * @memberof NotificationsApiRetrieveNotification
     */
    readonly inputRecordId?: string

    /**
     * input_record_row_id
     * @type {string}
     * @memberof NotificationsApiRetrieveNotification
     */
    readonly inputRecordRowId?: string

    /**
     * input_record_group_id
     * @type {string}
     * @memberof NotificationsApiRetrieveNotification
     */
    readonly inputRecordGroupId?: string
}

/**
 * Request parameters for updateNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationRequest
 */
export interface NotificationsApiUpdateNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiUpdateNotification
     */
    readonly id: string

    /**
     * input_record_id
     * @type {string}
     * @memberof NotificationsApiUpdateNotification
     */
    readonly inputRecordId?: string

    /**
     * input_record_row_id
     * @type {string}
     * @memberof NotificationsApiUpdateNotification
     */
    readonly inputRecordRowId?: string

    /**
     * input_record_group_id
     * @type {string}
     * @memberof NotificationsApiUpdateNotification
     */
    readonly inputRecordGroupId?: string

    /**
     * 
     * @type {Notification}
     * @memberof NotificationsApiUpdateNotification
     */
    readonly notification?: Notification
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {NotificationsApiCreateNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public createNotification(requestParameters: NotificationsApiCreateNotificationRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).createNotification(requestParameters.notification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiDestroyNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public destroyNotification(requestParameters: NotificationsApiDestroyNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).destroyNotification(requestParameters.id, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiListNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public listNotifications(requestParameters: NotificationsApiListNotificationsRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).listNotifications(requestParameters.page, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiPartialUpdateNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public partialUpdateNotification(requestParameters: NotificationsApiPartialUpdateNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).partialUpdateNotification(requestParameters.id, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, requestParameters.partialNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiRetrieveNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public retrieveNotification(requestParameters: NotificationsApiRetrieveNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).retrieveNotification(requestParameters.id, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiUpdateNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotification(requestParameters: NotificationsApiUpdateNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotification(requestParameters.id, requestParameters.inputRecordId, requestParameters.inputRecordRowId, requestParameters.inputRecordGroupId, requestParameters.notification, options).then((request) => request(this.axios, this.basePath));
    }
}

