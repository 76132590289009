import * as React from "react";
import { BaseLayout } from "../../shared/components/BaseLayout";
import { useApiModels } from "../../shared/hooks/useApiModels";
import { NotificationsApi } from "../../client";
import { Typography } from "antd";
import { NotificationTable } from "../components/NotificationTable";

interface IProps {}

export const NotificationOverview: React.FC<IProps> = (props) => {
    const {
        models: notifications,
        loading,
        tableProps,
    } = useApiModels(
        (page) => new NotificationsApi().listNotifications({ page }),
        "generic"
    );

    return (
        <BaseLayout>
            <Typography.Title level={3}>Notifications</Typography.Title>

            <NotificationTable
                notifications={notifications}
                loading={loading}
                tableProps={tableProps}
            />
        </BaseLayout>
    );
};
