/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ListTenants200Response } from '../models';
// @ts-ignore
import { PartialTenant } from '../models';
// @ts-ignore
import { Tenant } from '../models';
/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Tenant} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (tenant?: Tenant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenants/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTenant: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyTenant', 'id', id)
            const localVarPath = `/api/tenants/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Flip the tenant.
         * @param {string} id 
         * @param {Tenant} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flipTenant: async (id: string, tenant?: Tenant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('flipTenant', 'id', id)
            const localVarPath = `/api/tenants/{id}/flip/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants: async (page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tenants/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialTenant} [partialTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateTenant: async (id: string, partialTenant?: PartialTenant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateTenant', 'id', id)
            const localVarPath = `/api/tenants/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialTenant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTenant: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveTenant', 'id', id)
            const localVarPath = `/api/tenants/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Tenant} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: async (id: string, tenant?: Tenant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTenant', 'id', id)
            const localVarPath = `/api/tenants/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Tenant} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(tenant?: Tenant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenant(tenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.createTenant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyTenant(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyTenant(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.destroyTenant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Flip the tenant.
         * @param {string} id 
         * @param {Tenant} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async flipTenant(id: string, tenant?: Tenant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.flipTenant(id, tenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.flipTenant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenants(page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTenants200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenants(page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.listTenants']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialTenant} [partialTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateTenant(id: string, partialTenant?: PartialTenant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialTenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateTenant(id, partialTenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.partialUpdateTenant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveTenant(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveTenant(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.retrieveTenant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {Tenant} [tenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenant(id: string, tenant?: Tenant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tenant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenant(id, tenant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TenantsApi.updateTenant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantsApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantsApiCreateTenantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(requestParameters: TenantsApiCreateTenantRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Tenant> {
            return localVarFp.createTenant(requestParameters.tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantsApiDestroyTenantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTenant(requestParameters: TenantsApiDestroyTenantRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.destroyTenant(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Flip the tenant.
         * @param {TenantsApiFlipTenantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flipTenant(requestParameters: TenantsApiFlipTenantRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tenant> {
            return localVarFp.flipTenant(requestParameters.id, requestParameters.tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantsApiListTenantsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants(requestParameters: TenantsApiListTenantsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListTenants200Response> {
            return localVarFp.listTenants(requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantsApiPartialUpdateTenantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateTenant(requestParameters: TenantsApiPartialUpdateTenantRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartialTenant> {
            return localVarFp.partialUpdateTenant(requestParameters.id, requestParameters.partialTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantsApiRetrieveTenantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveTenant(requestParameters: TenantsApiRetrieveTenantRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tenant> {
            return localVarFp.retrieveTenant(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantsApiUpdateTenantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant(requestParameters: TenantsApiUpdateTenantRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tenant> {
            return localVarFp.updateTenant(requestParameters.id, requestParameters.tenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTenant operation in TenantsApi.
 * @export
 * @interface TenantsApiCreateTenantRequest
 */
export interface TenantsApiCreateTenantRequest {
    /**
     * 
     * @type {Tenant}
     * @memberof TenantsApiCreateTenant
     */
    readonly tenant?: Tenant
}

/**
 * Request parameters for destroyTenant operation in TenantsApi.
 * @export
 * @interface TenantsApiDestroyTenantRequest
 */
export interface TenantsApiDestroyTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantsApiDestroyTenant
     */
    readonly id: string
}

/**
 * Request parameters for flipTenant operation in TenantsApi.
 * @export
 * @interface TenantsApiFlipTenantRequest
 */
export interface TenantsApiFlipTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantsApiFlipTenant
     */
    readonly id: string

    /**
     * 
     * @type {Tenant}
     * @memberof TenantsApiFlipTenant
     */
    readonly tenant?: Tenant
}

/**
 * Request parameters for listTenants operation in TenantsApi.
 * @export
 * @interface TenantsApiListTenantsRequest
 */
export interface TenantsApiListTenantsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof TenantsApiListTenants
     */
    readonly page?: number
}

/**
 * Request parameters for partialUpdateTenant operation in TenantsApi.
 * @export
 * @interface TenantsApiPartialUpdateTenantRequest
 */
export interface TenantsApiPartialUpdateTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantsApiPartialUpdateTenant
     */
    readonly id: string

    /**
     * 
     * @type {PartialTenant}
     * @memberof TenantsApiPartialUpdateTenant
     */
    readonly partialTenant?: PartialTenant
}

/**
 * Request parameters for retrieveTenant operation in TenantsApi.
 * @export
 * @interface TenantsApiRetrieveTenantRequest
 */
export interface TenantsApiRetrieveTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantsApiRetrieveTenant
     */
    readonly id: string
}

/**
 * Request parameters for updateTenant operation in TenantsApi.
 * @export
 * @interface TenantsApiUpdateTenantRequest
 */
export interface TenantsApiUpdateTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantsApiUpdateTenant
     */
    readonly id: string

    /**
     * 
     * @type {Tenant}
     * @memberof TenantsApiUpdateTenant
     */
    readonly tenant?: Tenant
}

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * 
     * @param {TenantsApiCreateTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public createTenant(requestParameters: TenantsApiCreateTenantRequest = {}, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).createTenant(requestParameters.tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantsApiDestroyTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public destroyTenant(requestParameters: TenantsApiDestroyTenantRequest, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).destroyTenant(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Flip the tenant.
     * @param {TenantsApiFlipTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public flipTenant(requestParameters: TenantsApiFlipTenantRequest, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).flipTenant(requestParameters.id, requestParameters.tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantsApiListTenantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public listTenants(requestParameters: TenantsApiListTenantsRequest = {}, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).listTenants(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantsApiPartialUpdateTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public partialUpdateTenant(requestParameters: TenantsApiPartialUpdateTenantRequest, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).partialUpdateTenant(requestParameters.id, requestParameters.partialTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantsApiRetrieveTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public retrieveTenant(requestParameters: TenantsApiRetrieveTenantRequest, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).retrieveTenant(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantsApiUpdateTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public updateTenant(requestParameters: TenantsApiUpdateTenantRequest, options?: RawAxiosRequestConfig) {
        return TenantsApiFp(this.configuration).updateTenant(requestParameters.id, requestParameters.tenant, options).then((request) => request(this.axios, this.basePath));
    }
}

