/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { InputConnectorLog } from '../models';
// @ts-ignore
import { ListInputConnectorLogs200Response } from '../models';
// @ts-ignore
import { PartialInputConnectorLog } from '../models';
/**
 * InputConnectorLogsApi - axios parameter creator
 * @export
 */
export const InputConnectorLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InputConnectorLog} [inputConnectorLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputConnectorLog: async (inputConnectorLog?: InputConnectorLog, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_connector_logs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputConnectorLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputConnectorLog: async (id: string, inputConnectorId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyInputConnectorLog', 'id', id)
            const localVarPath = `/api/input_connector_logs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputConnectorId] input_connector_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputConnectorLogs: async (page?: number, inputConnectorId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_connector_logs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {PartialInputConnectorLog} [partialInputConnectorLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputConnectorLog: async (id: string, inputConnectorId?: string, partialInputConnectorLog?: PartialInputConnectorLog, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateInputConnectorLog', 'id', id)
            const localVarPath = `/api/input_connector_logs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialInputConnectorLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputConnectorLog: async (id: string, inputConnectorId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveInputConnectorLog', 'id', id)
            const localVarPath = `/api/input_connector_logs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {InputConnectorLog} [inputConnectorLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputConnectorLog: async (id: string, inputConnectorId?: string, inputConnectorLog?: InputConnectorLog, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInputConnectorLog', 'id', id)
            const localVarPath = `/api/input_connector_logs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputConnectorLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InputConnectorLogsApi - functional programming interface
 * @export
 */
export const InputConnectorLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InputConnectorLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InputConnectorLog} [inputConnectorLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInputConnectorLog(inputConnectorLog?: InputConnectorLog, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnectorLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInputConnectorLog(inputConnectorLog, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorLogsApi.createInputConnectorLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyInputConnectorLog(id: string, inputConnectorId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyInputConnectorLog(id, inputConnectorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorLogsApi.destroyInputConnectorLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputConnectorId] input_connector_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInputConnectorLogs(page?: number, inputConnectorId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInputConnectorLogs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInputConnectorLogs(page, inputConnectorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorLogsApi.listInputConnectorLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {PartialInputConnectorLog} [partialInputConnectorLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateInputConnectorLog(id: string, inputConnectorId?: string, partialInputConnectorLog?: PartialInputConnectorLog, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialInputConnectorLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateInputConnectorLog(id, inputConnectorId, partialInputConnectorLog, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorLogsApi.partialUpdateInputConnectorLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveInputConnectorLog(id: string, inputConnectorId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnectorLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveInputConnectorLog(id, inputConnectorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorLogsApi.retrieveInputConnectorLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {InputConnectorLog} [inputConnectorLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInputConnectorLog(id: string, inputConnectorId?: string, inputConnectorLog?: InputConnectorLog, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputConnectorLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInputConnectorLog(id, inputConnectorId, inputConnectorLog, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputConnectorLogsApi.updateInputConnectorLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InputConnectorLogsApi - factory interface
 * @export
 */
export const InputConnectorLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InputConnectorLogsApiFp(configuration)
    return {
        /**
         * 
         * @param {InputConnectorLogsApiCreateInputConnectorLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputConnectorLog(requestParameters: InputConnectorLogsApiCreateInputConnectorLogRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InputConnectorLog> {
            return localVarFp.createInputConnectorLog(requestParameters.inputConnectorLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorLogsApiDestroyInputConnectorLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputConnectorLog(requestParameters: InputConnectorLogsApiDestroyInputConnectorLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.destroyInputConnectorLog(requestParameters.id, requestParameters.inputConnectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorLogsApiListInputConnectorLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputConnectorLogs(requestParameters: InputConnectorLogsApiListInputConnectorLogsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInputConnectorLogs200Response> {
            return localVarFp.listInputConnectorLogs(requestParameters.page, requestParameters.inputConnectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorLogsApiPartialUpdateInputConnectorLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputConnectorLog(requestParameters: InputConnectorLogsApiPartialUpdateInputConnectorLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartialInputConnectorLog> {
            return localVarFp.partialUpdateInputConnectorLog(requestParameters.id, requestParameters.inputConnectorId, requestParameters.partialInputConnectorLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorLogsApiRetrieveInputConnectorLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputConnectorLog(requestParameters: InputConnectorLogsApiRetrieveInputConnectorLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnectorLog> {
            return localVarFp.retrieveInputConnectorLog(requestParameters.id, requestParameters.inputConnectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputConnectorLogsApiUpdateInputConnectorLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputConnectorLog(requestParameters: InputConnectorLogsApiUpdateInputConnectorLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputConnectorLog> {
            return localVarFp.updateInputConnectorLog(requestParameters.id, requestParameters.inputConnectorId, requestParameters.inputConnectorLog, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInputConnectorLog operation in InputConnectorLogsApi.
 * @export
 * @interface InputConnectorLogsApiCreateInputConnectorLogRequest
 */
export interface InputConnectorLogsApiCreateInputConnectorLogRequest {
    /**
     * 
     * @type {InputConnectorLog}
     * @memberof InputConnectorLogsApiCreateInputConnectorLog
     */
    readonly inputConnectorLog?: InputConnectorLog
}

/**
 * Request parameters for destroyInputConnectorLog operation in InputConnectorLogsApi.
 * @export
 * @interface InputConnectorLogsApiDestroyInputConnectorLogRequest
 */
export interface InputConnectorLogsApiDestroyInputConnectorLogRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorLogsApiDestroyInputConnectorLog
     */
    readonly id: string

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputConnectorLogsApiDestroyInputConnectorLog
     */
    readonly inputConnectorId?: string
}

/**
 * Request parameters for listInputConnectorLogs operation in InputConnectorLogsApi.
 * @export
 * @interface InputConnectorLogsApiListInputConnectorLogsRequest
 */
export interface InputConnectorLogsApiListInputConnectorLogsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof InputConnectorLogsApiListInputConnectorLogs
     */
    readonly page?: number

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputConnectorLogsApiListInputConnectorLogs
     */
    readonly inputConnectorId?: string
}

/**
 * Request parameters for partialUpdateInputConnectorLog operation in InputConnectorLogsApi.
 * @export
 * @interface InputConnectorLogsApiPartialUpdateInputConnectorLogRequest
 */
export interface InputConnectorLogsApiPartialUpdateInputConnectorLogRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorLogsApiPartialUpdateInputConnectorLog
     */
    readonly id: string

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputConnectorLogsApiPartialUpdateInputConnectorLog
     */
    readonly inputConnectorId?: string

    /**
     * 
     * @type {PartialInputConnectorLog}
     * @memberof InputConnectorLogsApiPartialUpdateInputConnectorLog
     */
    readonly partialInputConnectorLog?: PartialInputConnectorLog
}

/**
 * Request parameters for retrieveInputConnectorLog operation in InputConnectorLogsApi.
 * @export
 * @interface InputConnectorLogsApiRetrieveInputConnectorLogRequest
 */
export interface InputConnectorLogsApiRetrieveInputConnectorLogRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorLogsApiRetrieveInputConnectorLog
     */
    readonly id: string

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputConnectorLogsApiRetrieveInputConnectorLog
     */
    readonly inputConnectorId?: string
}

/**
 * Request parameters for updateInputConnectorLog operation in InputConnectorLogsApi.
 * @export
 * @interface InputConnectorLogsApiUpdateInputConnectorLogRequest
 */
export interface InputConnectorLogsApiUpdateInputConnectorLogRequest {
    /**
     * 
     * @type {string}
     * @memberof InputConnectorLogsApiUpdateInputConnectorLog
     */
    readonly id: string

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputConnectorLogsApiUpdateInputConnectorLog
     */
    readonly inputConnectorId?: string

    /**
     * 
     * @type {InputConnectorLog}
     * @memberof InputConnectorLogsApiUpdateInputConnectorLog
     */
    readonly inputConnectorLog?: InputConnectorLog
}

/**
 * InputConnectorLogsApi - object-oriented interface
 * @export
 * @class InputConnectorLogsApi
 * @extends {BaseAPI}
 */
export class InputConnectorLogsApi extends BaseAPI {
    /**
     * 
     * @param {InputConnectorLogsApiCreateInputConnectorLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorLogsApi
     */
    public createInputConnectorLog(requestParameters: InputConnectorLogsApiCreateInputConnectorLogRequest = {}, options?: RawAxiosRequestConfig) {
        return InputConnectorLogsApiFp(this.configuration).createInputConnectorLog(requestParameters.inputConnectorLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorLogsApiDestroyInputConnectorLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorLogsApi
     */
    public destroyInputConnectorLog(requestParameters: InputConnectorLogsApiDestroyInputConnectorLogRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorLogsApiFp(this.configuration).destroyInputConnectorLog(requestParameters.id, requestParameters.inputConnectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorLogsApiListInputConnectorLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorLogsApi
     */
    public listInputConnectorLogs(requestParameters: InputConnectorLogsApiListInputConnectorLogsRequest = {}, options?: RawAxiosRequestConfig) {
        return InputConnectorLogsApiFp(this.configuration).listInputConnectorLogs(requestParameters.page, requestParameters.inputConnectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorLogsApiPartialUpdateInputConnectorLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorLogsApi
     */
    public partialUpdateInputConnectorLog(requestParameters: InputConnectorLogsApiPartialUpdateInputConnectorLogRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorLogsApiFp(this.configuration).partialUpdateInputConnectorLog(requestParameters.id, requestParameters.inputConnectorId, requestParameters.partialInputConnectorLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorLogsApiRetrieveInputConnectorLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorLogsApi
     */
    public retrieveInputConnectorLog(requestParameters: InputConnectorLogsApiRetrieveInputConnectorLogRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorLogsApiFp(this.configuration).retrieveInputConnectorLog(requestParameters.id, requestParameters.inputConnectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputConnectorLogsApiUpdateInputConnectorLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputConnectorLogsApi
     */
    public updateInputConnectorLog(requestParameters: InputConnectorLogsApiUpdateInputConnectorLogRequest, options?: RawAxiosRequestConfig) {
        return InputConnectorLogsApiFp(this.configuration).updateInputConnectorLog(requestParameters.id, requestParameters.inputConnectorId, requestParameters.inputConnectorLog, options).then((request) => request(this.axios, this.basePath));
    }
}

