import * as React from "react";
import { useState } from "react";
import { Button, DatePicker, Radio } from "antd";
import { Moment } from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

interface IProps {
    value: [Moment, Moment];
    onChange: (start: Moment, end: Moment) => void;
    style?: React.CSSProperties;
}

type PickerType = "date" | "week" | "month" | "quarter" | "year";

export const CustomDatePicker: React.FC<IProps> = (props) => {
    const getDefaultPickerType = (): PickerType => {
        const days = props.value[1].diff(props.value[0], "days");

        if (days <= 1) {
            return "date";
        } else if (days <= 7) {
            return "week";
        } else if (days <= 30) {
            return "month";
        } else if (days <= 93) {
            return "quarter";
        } else {
            return "year";
        }
    };

    const [pickerType, setPickerType] = useState<PickerType>(
        getDefaultPickerType()
    );

    const changeValue = (picker: PickerType, date: Moment) => {
        setPickerType(picker);
        props.onChange(
            date.clone().startOf(picker),
            date.clone().endOf(picker)
        );
    };

    const moveDate = (direction: "left" | "right") => {
        let days = props.value[1].diff(props.value[0], "days") + 1;
        if (direction === "left") {
            days = days * -1;
        }
        changeValue(pickerType, props.value[0].clone().add(days, "days"));
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                ...props.style,
            }}
        >
            <Button icon={<LeftOutlined />} onClick={() => moveDate("left")} />
            <DatePicker
                picker={pickerType}
                value={props.value[0]}
                onChange={(newDate) => {
                    if (newDate) {
                        changeValue(pickerType, newDate);
                    }
                }}
                allowClear={false}
                style={{
                    margin: "0 0.5em",
                }}
            />
            <Button
                icon={<RightOutlined />}
                onClick={() => moveDate("right")}
            />

            <div
                style={{
                    marginLeft: "1em",
                }}
            >
                <Radio.Group
                    value={pickerType}
                    onChange={(e) => {
                        changeValue(e.target.value, props.value[0]);
                    }}
                >
                    <Radio.Button value="date">Dag</Radio.Button>
                    <Radio.Button value="week">Week</Radio.Button>
                    <Radio.Button value="month">Maand</Radio.Button>
                    <Radio.Button value="quarter">Kwartiel</Radio.Button>
                    <Radio.Button value="year">Jaar</Radio.Button>
                </Radio.Group>
            </div>
        </div>
    );
};
