import * as React from "react";
import { Notification } from "../../client";
import { ITableProps } from "../../shared/hooks/useApiModels";
import { Table } from "antd";
import moment from "moment/moment";
import { DATE_TIME_FORMAT } from "../../shared/constants";
import { Link } from "react-router-dom";

interface IProps {
    notifications: Notification[];
    loading: boolean;
    tableProps: ITableProps<Notification>;
}

export const NotificationTable: React.FC<IProps> = (props) => {
    return (
        <Table<Notification>
            dataSource={props.notifications}
            loading={props.loading}
            rowKey="id"
            title={() => "Notifications"}
            {...props.tableProps}
            columns={[
                {
                    key: "created",
                    dataIndex: "created",
                    title: "Created",
                    render: (created) =>
                        moment(created).format(DATE_TIME_FORMAT),
                },
                {
                    key: "description",
                    dataIndex: "description",
                    title: "Description",
                },

                {
                    key: "input_record_id",
                    dataIndex: "input_record_id",
                    title: "Input record",
                    render: (inputRecordId) => (
                        <Link to={`/input-records/${inputRecordId}`}>
                            {inputRecordId}
                        </Link>
                    ),
                },
                {
                    key: "input_record_group_id",
                    dataIndex: "input_record_group_id",
                    title: "Input record group",
                    render: (inputRecordGroupId) => (
                        <Link to={`/groups/${inputRecordGroupId}`}>
                            {inputRecordGroupId}
                        </Link>
                    ),
                },
                {
                    key: "exception",
                    dataIndex: "exception",
                    title: "Exception",
                },
            ]}
        />
    );
};
