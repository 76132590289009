import * as React from "react";
import { useState } from "react";
import { Modal, Radio } from "antd";
import { XMLTreeView } from "./XMLTreeView";
import { parseXML } from "../functions/xml";

interface IProps {
    data: string | object;
    onClose: () => void;
}

export const RawDataModal: React.FC<IProps> = (props) => {
    const [isParsed, setIsParsed] = useState(true);

    const renderValue = () => {
        if (!isParsed) {
            return <>{props.data}</>;
        }
        // If its already a dict don't parse it.
        if (typeof props.data == "object") {
            return <pre>{JSON.stringify(props.data, null, 4)}</pre>;
        }

        const data = props.data.trim();

        try {
            if (data.startsWith("{")) {
                // Try JSON.
                return (
                    <pre>{JSON.stringify(JSON.parse(props.data), null, 4)}</pre>
                );
            }
        } catch (e) {
            console.error(e);
        }

        try {
            // Try XML.
            if (data.startsWith("<")) {
                const tree = parseXML(data);
                if (tree) {
                    return <XMLTreeView tree={tree} />;
                }
            }
        } catch (e) {
            console.error(e);
        }

        return <pre>{props.data}</pre>;
    };

    return (
        <Modal
            open={true}
            onCancel={() => props.onClose()}
            title={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        verticalAlign: "middle",
                        alignContent: "center",
                        paddingRight: "2em",
                    }}
                >
                    <span
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                        }}
                    >
                        Raw data
                    </span>
                    <Radio.Group
                        value={isParsed}
                        buttonStyle="solid"
                        onChange={(e) => setIsParsed(e.target.value)}
                    >
                        <Radio.Button value={true}>Parsed</Radio.Button>
                        <Radio.Button value={false}>Raw</Radio.Button>
                    </Radio.Group>
                </div>
            }
            width="1100px"
        >
            <div>{renderValue()}</div>
        </Modal>
    );
};
