import * as React from "react";
import { GlobalContext } from "../../context/GlobalContextProvider";
import { Modal, Select } from "antd";
import { useEffectOnce } from "../../helpers/useEffectOnce";
import { Tenant, TenantsApi } from "../../client";

interface IProps {}

interface IOption {
    value: string;
    label: string;
    onSelect: () => Promise<void>;
}

export const HotkeySwitch: React.FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const [options, setOptions] = React.useState<IOption[]>([]);

    const selectRef = React.useRef<any>();
    const globalContext = React.useContext(GlobalContext);

    const isSuperUser = globalContext.user?.isSuperuser || false;

    const onKeyDown = (event: KeyboardEvent) => {
        if (
            (event.key === " " && event.ctrlKey) ||
            (event.key === "k" && event.ctrlKey)
        ) {
            setIsOpen((val) => !val);
            setTimeout(() => {
                if (selectRef.current) {
                    selectRef.current.focus();
                }
            }, 10);
            event.preventDefault();
        }
    };

    useEffectOnce(() => {
        window.addEventListener("keydown", onKeyDown);

        new TenantsApi().listTenants().then((res) => {
            const tenants: Tenant[] = [
                ...res.data.results,
                {
                    id: 0,
                    name: "Clear tenant",
                },
            ];
            const newOptions: IOption[] = tenants.map((tenant) => ({
                label: tenant.name,
                value: `tenant-${tenant.id}`,
                onSelect: async () => {
                    await new TenantsApi().flipTenant({
                        id: tenant.id.toString(),
                    });

                    // Reload the window.
                    window.location.reload();
                },
            }));
            setOptions(newOptions);
        });

        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    });

    if (!isSuperUser) {
        return null;
    }

    if (!isOpen) {
        return null;
    }

    return (
        <Modal
            open={true}
            onCancel={() => setIsOpen(false)}
            footer={null}
            closeIcon={<></>}
        >
            <Select
                ref={selectRef}
                showSearch={true}
                searchValue={searchValue}
                onSearch={setSearchValue}
                style={{ width: "100%" }}
                size="large"
                autoFocus={true}
                placeholder={"search"}
                options={options}
                onSelect={(optionValue: string) => {
                    const option = options.find((o) => o.value === optionValue);
                    if (option) {
                        option.onSelect().then(() => setIsOpen(false));
                    }
                }}
                filterOption={(inputValue, option) =>
                    option
                        ? option.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                        : true
                }
            />
        </Modal>
    );
};
