/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { InputRecord } from '../models';
// @ts-ignore
import { InputRecordAdditionalData } from '../models';
// @ts-ignore
import { ListInputRecords200Response } from '../models';
// @ts-ignore
import { PartialInputRecord } from '../models';
/**
 * InputRecordsApi - axios parameter creator
 * @export
 */
export const InputRecordsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the additional data per input record.
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {AdditionalDataInputRecordAdditionalDataOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalDataInputRecordAdditionalData: async (page?: number, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: AdditionalDataInputRecordAdditionalDataOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_records/additional_data/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = createdBefore;
            }

            if (isConverted !== undefined) {
                localVarQueryParameter['is_converted'] = isConverted;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputRecord: async (inputRecord?: InputRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_records/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {DestroyInputRecordOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputRecord: async (id: string, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: DestroyInputRecordOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyInputRecord', 'id', id)
            const localVarPath = `/api/input_records/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = createdBefore;
            }

            if (isConverted !== undefined) {
                localVarQueryParameter['is_converted'] = isConverted;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInputRecord: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadInputRecord', 'id', id)
            const localVarPath = `/api/input_records/{id}/download/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {ListInputRecordsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputRecords: async (page?: number, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: ListInputRecordsOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_records/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = createdBefore;
            }

            if (isConverted !== undefined) {
                localVarQueryParameter['is_converted'] = isConverted;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {PartialUpdateInputRecordOrderingEnum} [ordering] Ordering
         * @param {PartialInputRecord} [partialInputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputRecord: async (id: string, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: PartialUpdateInputRecordOrderingEnum, partialInputRecord?: PartialInputRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateInputRecord', 'id', id)
            const localVarPath = `/api/input_records/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = createdBefore;
            }

            if (isConverted !== undefined) {
                localVarQueryParameter['is_converted'] = isConverted;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialInputRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {RetrieveInputRecordOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputRecord: async (id: string, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: RetrieveInputRecordOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveInputRecord', 'id', id)
            const localVarPath = `/api/input_records/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = createdBefore;
            }

            if (isConverted !== undefined) {
                localVarQueryParameter['is_converted'] = isConverted;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retry the conversion of the failing rows in the InputRecord.
         * @param {string} id 
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryConvertInputRecord: async (id: string, inputRecord?: InputRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retryConvertInputRecord', 'id', id)
            const localVarPath = `/api/input_records/{id}/retry_convert/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retry the export of the failing rows in the InputRecord.
         * @param {string} id 
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryExportInputRecord: async (id: string, inputRecord?: InputRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retryExportInputRecord', 'id', id)
            const localVarPath = `/api/input_records/{id}/retry_export/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retry the convert/export of the failing rows in the InputRecord.
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {RetryInputRecordOrderingEnum} [ordering] Ordering
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryInputRecord: async (inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: RetryInputRecordOrderingEnum, inputRecord?: InputRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_records/retry/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = createdBefore;
            }

            if (isConverted !== undefined) {
                localVarQueryParameter['is_converted'] = isConverted;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {UpdateInputRecordOrderingEnum} [ordering] Ordering
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputRecord: async (id: string, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: UpdateInputRecordOrderingEnum, inputRecord?: InputRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInputRecord', 'id', id)
            const localVarPath = `/api/input_records/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputConnectorId !== undefined) {
                localVarQueryParameter['input_connector_id'] = inputConnectorId;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['created_after'] = createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['created_before'] = createdBefore;
            }

            if (isConverted !== undefined) {
                localVarQueryParameter['is_converted'] = isConverted;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InputRecordsApi - functional programming interface
 * @export
 */
export const InputRecordsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InputRecordsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the additional data per input record.
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {AdditionalDataInputRecordAdditionalDataOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async additionalDataInputRecordAdditionalData(page?: number, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: AdditionalDataInputRecordAdditionalDataOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: InputRecordAdditionalData; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.additionalDataInputRecordAdditionalData(page, inputConnectorId, createdAfter, createdBefore, isConverted, isExported, all, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.additionalDataInputRecordAdditionalData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInputRecord(inputRecord?: InputRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInputRecord(inputRecord, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.createInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {DestroyInputRecordOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyInputRecord(id: string, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: DestroyInputRecordOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyInputRecord(id, inputConnectorId, createdAfter, createdBefore, isConverted, isExported, all, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.destroyInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInputRecord(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInputRecord(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.downloadInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {ListInputRecordsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInputRecords(page?: number, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: ListInputRecordsOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInputRecords200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInputRecords(page, inputConnectorId, createdAfter, createdBefore, isConverted, isExported, all, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.listInputRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {PartialUpdateInputRecordOrderingEnum} [ordering] Ordering
         * @param {PartialInputRecord} [partialInputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateInputRecord(id: string, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: PartialUpdateInputRecordOrderingEnum, partialInputRecord?: PartialInputRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialInputRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateInputRecord(id, inputConnectorId, createdAfter, createdBefore, isConverted, isExported, all, ordering, partialInputRecord, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.partialUpdateInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {RetrieveInputRecordOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveInputRecord(id: string, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: RetrieveInputRecordOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveInputRecord(id, inputConnectorId, createdAfter, createdBefore, isConverted, isExported, all, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.retrieveInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retry the conversion of the failing rows in the InputRecord.
         * @param {string} id 
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryConvertInputRecord(id: string, inputRecord?: InputRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryConvertInputRecord(id, inputRecord, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.retryConvertInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retry the export of the failing rows in the InputRecord.
         * @param {string} id 
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryExportInputRecord(id: string, inputRecord?: InputRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryExportInputRecord(id, inputRecord, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.retryExportInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retry the convert/export of the failing rows in the InputRecord.
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {RetryInputRecordOrderingEnum} [ordering] Ordering
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryInputRecord(inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: RetryInputRecordOrderingEnum, inputRecord?: InputRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryInputRecord(inputConnectorId, createdAfter, createdBefore, isConverted, isExported, all, ordering, inputRecord, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.retryInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputConnectorId] input_connector_id
         * @param {string} [createdAfter] created_after
         * @param {string} [createdBefore] created_before
         * @param {string} [isConverted] is_converted
         * @param {string} [isExported] is_exported
         * @param {string} [all] all
         * @param {UpdateInputRecordOrderingEnum} [ordering] Ordering
         * @param {InputRecord} [inputRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInputRecord(id: string, inputConnectorId?: string, createdAfter?: string, createdBefore?: string, isConverted?: string, isExported?: string, all?: string, ordering?: UpdateInputRecordOrderingEnum, inputRecord?: InputRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInputRecord(id, inputConnectorId, createdAfter, createdBefore, isConverted, isExported, all, ordering, inputRecord, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordsApi.updateInputRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InputRecordsApi - factory interface
 * @export
 */
export const InputRecordsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InputRecordsApiFp(configuration)
    return {
        /**
         * Get the additional data per input record.
         * @param {InputRecordsApiAdditionalDataInputRecordAdditionalDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        additionalDataInputRecordAdditionalData(requestParameters: InputRecordsApiAdditionalDataInputRecordAdditionalDataRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: InputRecordAdditionalData; }> {
            return localVarFp.additionalDataInputRecordAdditionalData(requestParameters.page, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordsApiCreateInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputRecord(requestParameters: InputRecordsApiCreateInputRecordRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InputRecord> {
            return localVarFp.createInputRecord(requestParameters.inputRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordsApiDestroyInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputRecord(requestParameters: InputRecordsApiDestroyInputRecordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.destroyInputRecord(requestParameters.id, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordsApiDownloadInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInputRecord(requestParameters: InputRecordsApiDownloadInputRecordRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecord> {
            return localVarFp.downloadInputRecord(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordsApiListInputRecordsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputRecords(requestParameters: InputRecordsApiListInputRecordsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInputRecords200Response> {
            return localVarFp.listInputRecords(requestParameters.page, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordsApiPartialUpdateInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputRecord(requestParameters: InputRecordsApiPartialUpdateInputRecordRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartialInputRecord> {
            return localVarFp.partialUpdateInputRecord(requestParameters.id, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, requestParameters.partialInputRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordsApiRetrieveInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputRecord(requestParameters: InputRecordsApiRetrieveInputRecordRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecord> {
            return localVarFp.retrieveInputRecord(requestParameters.id, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * Retry the conversion of the failing rows in the InputRecord.
         * @param {InputRecordsApiRetryConvertInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryConvertInputRecord(requestParameters: InputRecordsApiRetryConvertInputRecordRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecord> {
            return localVarFp.retryConvertInputRecord(requestParameters.id, requestParameters.inputRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * Retry the export of the failing rows in the InputRecord.
         * @param {InputRecordsApiRetryExportInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryExportInputRecord(requestParameters: InputRecordsApiRetryExportInputRecordRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecord> {
            return localVarFp.retryExportInputRecord(requestParameters.id, requestParameters.inputRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * Retry the convert/export of the failing rows in the InputRecord.
         * @param {InputRecordsApiRetryInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryInputRecord(requestParameters: InputRecordsApiRetryInputRecordRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InputRecord> {
            return localVarFp.retryInputRecord(requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, requestParameters.inputRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordsApiUpdateInputRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputRecord(requestParameters: InputRecordsApiUpdateInputRecordRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecord> {
            return localVarFp.updateInputRecord(requestParameters.id, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, requestParameters.inputRecord, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for additionalDataInputRecordAdditionalData operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiAdditionalDataInputRecordAdditionalDataRequest
 */
export interface InputRecordsApiAdditionalDataInputRecordAdditionalDataRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof InputRecordsApiAdditionalDataInputRecordAdditionalData
     */
    readonly page?: number

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputRecordsApiAdditionalDataInputRecordAdditionalData
     */
    readonly inputConnectorId?: string

    /**
     * created_after
     * @type {string}
     * @memberof InputRecordsApiAdditionalDataInputRecordAdditionalData
     */
    readonly createdAfter?: string

    /**
     * created_before
     * @type {string}
     * @memberof InputRecordsApiAdditionalDataInputRecordAdditionalData
     */
    readonly createdBefore?: string

    /**
     * is_converted
     * @type {string}
     * @memberof InputRecordsApiAdditionalDataInputRecordAdditionalData
     */
    readonly isConverted?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordsApiAdditionalDataInputRecordAdditionalData
     */
    readonly isExported?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordsApiAdditionalDataInputRecordAdditionalData
     */
    readonly all?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordsApiAdditionalDataInputRecordAdditionalData
     */
    readonly ordering?: AdditionalDataInputRecordAdditionalDataOrderingEnum
}

/**
 * Request parameters for createInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiCreateInputRecordRequest
 */
export interface InputRecordsApiCreateInputRecordRequest {
    /**
     * 
     * @type {InputRecord}
     * @memberof InputRecordsApiCreateInputRecord
     */
    readonly inputRecord?: InputRecord
}

/**
 * Request parameters for destroyInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiDestroyInputRecordRequest
 */
export interface InputRecordsApiDestroyInputRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordsApiDestroyInputRecord
     */
    readonly id: string

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputRecordsApiDestroyInputRecord
     */
    readonly inputConnectorId?: string

    /**
     * created_after
     * @type {string}
     * @memberof InputRecordsApiDestroyInputRecord
     */
    readonly createdAfter?: string

    /**
     * created_before
     * @type {string}
     * @memberof InputRecordsApiDestroyInputRecord
     */
    readonly createdBefore?: string

    /**
     * is_converted
     * @type {string}
     * @memberof InputRecordsApiDestroyInputRecord
     */
    readonly isConverted?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordsApiDestroyInputRecord
     */
    readonly isExported?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordsApiDestroyInputRecord
     */
    readonly all?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordsApiDestroyInputRecord
     */
    readonly ordering?: DestroyInputRecordOrderingEnum
}

/**
 * Request parameters for downloadInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiDownloadInputRecordRequest
 */
export interface InputRecordsApiDownloadInputRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordsApiDownloadInputRecord
     */
    readonly id: string
}

/**
 * Request parameters for listInputRecords operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiListInputRecordsRequest
 */
export interface InputRecordsApiListInputRecordsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof InputRecordsApiListInputRecords
     */
    readonly page?: number

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputRecordsApiListInputRecords
     */
    readonly inputConnectorId?: string

    /**
     * created_after
     * @type {string}
     * @memberof InputRecordsApiListInputRecords
     */
    readonly createdAfter?: string

    /**
     * created_before
     * @type {string}
     * @memberof InputRecordsApiListInputRecords
     */
    readonly createdBefore?: string

    /**
     * is_converted
     * @type {string}
     * @memberof InputRecordsApiListInputRecords
     */
    readonly isConverted?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordsApiListInputRecords
     */
    readonly isExported?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordsApiListInputRecords
     */
    readonly all?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordsApiListInputRecords
     */
    readonly ordering?: ListInputRecordsOrderingEnum
}

/**
 * Request parameters for partialUpdateInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiPartialUpdateInputRecordRequest
 */
export interface InputRecordsApiPartialUpdateInputRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly id: string

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly inputConnectorId?: string

    /**
     * created_after
     * @type {string}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly createdAfter?: string

    /**
     * created_before
     * @type {string}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly createdBefore?: string

    /**
     * is_converted
     * @type {string}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly isConverted?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly isExported?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly all?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly ordering?: PartialUpdateInputRecordOrderingEnum

    /**
     * 
     * @type {PartialInputRecord}
     * @memberof InputRecordsApiPartialUpdateInputRecord
     */
    readonly partialInputRecord?: PartialInputRecord
}

/**
 * Request parameters for retrieveInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiRetrieveInputRecordRequest
 */
export interface InputRecordsApiRetrieveInputRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordsApiRetrieveInputRecord
     */
    readonly id: string

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputRecordsApiRetrieveInputRecord
     */
    readonly inputConnectorId?: string

    /**
     * created_after
     * @type {string}
     * @memberof InputRecordsApiRetrieveInputRecord
     */
    readonly createdAfter?: string

    /**
     * created_before
     * @type {string}
     * @memberof InputRecordsApiRetrieveInputRecord
     */
    readonly createdBefore?: string

    /**
     * is_converted
     * @type {string}
     * @memberof InputRecordsApiRetrieveInputRecord
     */
    readonly isConverted?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordsApiRetrieveInputRecord
     */
    readonly isExported?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordsApiRetrieveInputRecord
     */
    readonly all?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordsApiRetrieveInputRecord
     */
    readonly ordering?: RetrieveInputRecordOrderingEnum
}

/**
 * Request parameters for retryConvertInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiRetryConvertInputRecordRequest
 */
export interface InputRecordsApiRetryConvertInputRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordsApiRetryConvertInputRecord
     */
    readonly id: string

    /**
     * 
     * @type {InputRecord}
     * @memberof InputRecordsApiRetryConvertInputRecord
     */
    readonly inputRecord?: InputRecord
}

/**
 * Request parameters for retryExportInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiRetryExportInputRecordRequest
 */
export interface InputRecordsApiRetryExportInputRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordsApiRetryExportInputRecord
     */
    readonly id: string

    /**
     * 
     * @type {InputRecord}
     * @memberof InputRecordsApiRetryExportInputRecord
     */
    readonly inputRecord?: InputRecord
}

/**
 * Request parameters for retryInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiRetryInputRecordRequest
 */
export interface InputRecordsApiRetryInputRecordRequest {
    /**
     * input_connector_id
     * @type {string}
     * @memberof InputRecordsApiRetryInputRecord
     */
    readonly inputConnectorId?: string

    /**
     * created_after
     * @type {string}
     * @memberof InputRecordsApiRetryInputRecord
     */
    readonly createdAfter?: string

    /**
     * created_before
     * @type {string}
     * @memberof InputRecordsApiRetryInputRecord
     */
    readonly createdBefore?: string

    /**
     * is_converted
     * @type {string}
     * @memberof InputRecordsApiRetryInputRecord
     */
    readonly isConverted?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordsApiRetryInputRecord
     */
    readonly isExported?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordsApiRetryInputRecord
     */
    readonly all?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordsApiRetryInputRecord
     */
    readonly ordering?: RetryInputRecordOrderingEnum

    /**
     * 
     * @type {InputRecord}
     * @memberof InputRecordsApiRetryInputRecord
     */
    readonly inputRecord?: InputRecord
}

/**
 * Request parameters for updateInputRecord operation in InputRecordsApi.
 * @export
 * @interface InputRecordsApiUpdateInputRecordRequest
 */
export interface InputRecordsApiUpdateInputRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly id: string

    /**
     * input_connector_id
     * @type {string}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly inputConnectorId?: string

    /**
     * created_after
     * @type {string}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly createdAfter?: string

    /**
     * created_before
     * @type {string}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly createdBefore?: string

    /**
     * is_converted
     * @type {string}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly isConverted?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly isExported?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly all?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly ordering?: UpdateInputRecordOrderingEnum

    /**
     * 
     * @type {InputRecord}
     * @memberof InputRecordsApiUpdateInputRecord
     */
    readonly inputRecord?: InputRecord
}

/**
 * InputRecordsApi - object-oriented interface
 * @export
 * @class InputRecordsApi
 * @extends {BaseAPI}
 */
export class InputRecordsApi extends BaseAPI {
    /**
     * Get the additional data per input record.
     * @param {InputRecordsApiAdditionalDataInputRecordAdditionalDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public additionalDataInputRecordAdditionalData(requestParameters: InputRecordsApiAdditionalDataInputRecordAdditionalDataRequest = {}, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).additionalDataInputRecordAdditionalData(requestParameters.page, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordsApiCreateInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public createInputRecord(requestParameters: InputRecordsApiCreateInputRecordRequest = {}, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).createInputRecord(requestParameters.inputRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordsApiDestroyInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public destroyInputRecord(requestParameters: InputRecordsApiDestroyInputRecordRequest, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).destroyInputRecord(requestParameters.id, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordsApiDownloadInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public downloadInputRecord(requestParameters: InputRecordsApiDownloadInputRecordRequest, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).downloadInputRecord(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordsApiListInputRecordsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public listInputRecords(requestParameters: InputRecordsApiListInputRecordsRequest = {}, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).listInputRecords(requestParameters.page, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordsApiPartialUpdateInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public partialUpdateInputRecord(requestParameters: InputRecordsApiPartialUpdateInputRecordRequest, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).partialUpdateInputRecord(requestParameters.id, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, requestParameters.partialInputRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordsApiRetrieveInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public retrieveInputRecord(requestParameters: InputRecordsApiRetrieveInputRecordRequest, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).retrieveInputRecord(requestParameters.id, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retry the conversion of the failing rows in the InputRecord.
     * @param {InputRecordsApiRetryConvertInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public retryConvertInputRecord(requestParameters: InputRecordsApiRetryConvertInputRecordRequest, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).retryConvertInputRecord(requestParameters.id, requestParameters.inputRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retry the export of the failing rows in the InputRecord.
     * @param {InputRecordsApiRetryExportInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public retryExportInputRecord(requestParameters: InputRecordsApiRetryExportInputRecordRequest, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).retryExportInputRecord(requestParameters.id, requestParameters.inputRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retry the convert/export of the failing rows in the InputRecord.
     * @param {InputRecordsApiRetryInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public retryInputRecord(requestParameters: InputRecordsApiRetryInputRecordRequest = {}, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).retryInputRecord(requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, requestParameters.inputRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordsApiUpdateInputRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordsApi
     */
    public updateInputRecord(requestParameters: InputRecordsApiUpdateInputRecordRequest, options?: RawAxiosRequestConfig) {
        return InputRecordsApiFp(this.configuration).updateInputRecord(requestParameters.id, requestParameters.inputConnectorId, requestParameters.createdAfter, requestParameters.createdBefore, requestParameters.isConverted, requestParameters.isExported, requestParameters.all, requestParameters.ordering, requestParameters.inputRecord, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AdditionalDataInputRecordAdditionalDataOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type AdditionalDataInputRecordAdditionalDataOrderingEnum = typeof AdditionalDataInputRecordAdditionalDataOrderingEnum[keyof typeof AdditionalDataInputRecordAdditionalDataOrderingEnum];
/**
 * @export
 */
export const DestroyInputRecordOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type DestroyInputRecordOrderingEnum = typeof DestroyInputRecordOrderingEnum[keyof typeof DestroyInputRecordOrderingEnum];
/**
 * @export
 */
export const ListInputRecordsOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type ListInputRecordsOrderingEnum = typeof ListInputRecordsOrderingEnum[keyof typeof ListInputRecordsOrderingEnum];
/**
 * @export
 */
export const PartialUpdateInputRecordOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type PartialUpdateInputRecordOrderingEnum = typeof PartialUpdateInputRecordOrderingEnum[keyof typeof PartialUpdateInputRecordOrderingEnum];
/**
 * @export
 */
export const RetrieveInputRecordOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type RetrieveInputRecordOrderingEnum = typeof RetrieveInputRecordOrderingEnum[keyof typeof RetrieveInputRecordOrderingEnum];
/**
 * @export
 */
export const RetryInputRecordOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type RetryInputRecordOrderingEnum = typeof RetryInputRecordOrderingEnum[keyof typeof RetryInputRecordOrderingEnum];
/**
 * @export
 */
export const UpdateInputRecordOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type UpdateInputRecordOrderingEnum = typeof UpdateInputRecordOrderingEnum[keyof typeof UpdateInputRecordOrderingEnum];
