import {
    InputRecord,
    InputRecordAdditionalData,
    InputRecordsApi,
    InputRecordsApiListInputRecordsRequest,
    ListInputRecords200Response,
} from "../../client";
import { useApiModels } from "../../shared/hooks/useApiModels";
import { AxiosResponse } from "axios";
import { DependencyList } from "react";

export type InputRecordWithAdditionalData = InputRecord &
    InputRecordAdditionalData;

export const useInputRecords = (
    baseFilters: Partial<InputRecordsApiListInputRecordsRequest>,
    dependencies?: DependencyList
) => {
    return useApiModels<InputRecordWithAdditionalData>(
        async (page, filters) => {
            const inputFilters: InputRecordsApiListInputRecordsRequest = {
                page,
                ...baseFilters,
                ordering: "-created",
                ...filters,
            };

            const inputRecordsResult =
                await new InputRecordsApi().listInputRecords(inputFilters);

            const additionalDataResult =
                await new InputRecordsApi().additionalDataInputRecordAdditionalData(
                    inputFilters
                );

            inputRecordsResult.data.results =
                inputRecordsResult.data.results.map((row) => ({
                    ...row,
                    ...additionalDataResult.data[row.id],
                }));

            const response: AxiosResponse<
                Omit<ListInputRecords200Response, "results"> & {
                    results: Array<InputRecord & InputRecordAdditionalData>;
                },
                any
            > = {
                ...inputRecordsResult,
                data: {
                    ...inputRecordsResult.data,
                    results: inputRecordsResult.data.results.map((row) => ({
                        ...row,
                        ...additionalDataResult.data[row.id],
                    })),
                },
            };

            return response;
        },
        "inputRecords",
        dependencies
    );
};
