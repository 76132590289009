import { Modal, Upload } from "antd";
import * as React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { getAxios } from "../../helpers/axiosClient";

interface IProps {
    inputConnectorId: string;
    onClose: () => void;
}

export const InputConnectorManualUploadModal: React.FC<IProps> = (props) => {
    const [file, setFile] = React.useState<File | null>(null);

    const onUpload = () => {
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        return getAxios()
            .post(
                `/api/input_connectors/${props.inputConnectorId}/upload/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then(() => props.onClose());
    };

    return (
        <Modal
            open={true}
            onCancel={() => props.onClose()}
            onOk={() => onUpload()}
            okButtonProps={{
                disabled: !file,
            }}
        >
            <Upload.Dragger
                multiple={false}
                showUploadList={false}
                customRequest={(options) => {
                    if (options.file instanceof File) {
                        setFile(options.file);
                    }
                }}
            >
                {file ? (
                    <p>{file.name}</p>
                ) : (
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag file to this area to upload
                        </p>
                    </>
                )}
            </Upload.Dragger>
        </Modal>
    );
};
