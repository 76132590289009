import React, { useEffect } from "react";
import { usePrevious } from "./usePrevious";

export function useApiModel<T extends object>(
    id: number | string | null,
    func: (id: string) => Promise<{ data: T }>
): {
    model: T | null;
    loading: boolean;
    fetch: () => void;
} {
    const [apiModel, setApiModel] = React.useState<T | null>(null);
    const [loading, setLoading] = React.useState(false);
    const previousId = usePrevious(id);

    const fetch = () => {
        if (id === null) {
            setApiModel(null);
            return;
        }

        setLoading(true);
        func(String(id)).then((res) => {
            setApiModel(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (id !== previousId && !loading) {
            fetch();
        }
    }, [id, previousId, fetch, loading]);

    return {
        model: apiModel,
        loading,
        fetch,
    };
}
