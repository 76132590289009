/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { InputRecordRow } from '../models';
// @ts-ignore
import { ListInputRecordRows200Response } from '../models';
// @ts-ignore
import { PartialInputRecordRow } from '../models';
/**
 * InputRecordRowsApi - axios parameter creator
 * @export
 */
export const InputRecordRowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InputRecordRow} [inputRecordRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputRecordRow: async (inputRecordRow?: InputRecordRow, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_record_rows/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecordRow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {DestroyInputRecordRowOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputRecordRow: async (id: string, inputRecordId?: string, all?: string, isExported?: string, ordering?: DestroyInputRecordRowOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyInputRecordRow', 'id', id)
            const localVarPath = `/api/input_record_rows/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {ListInputRecordRowsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputRecordRows: async (page?: number, inputRecordId?: string, all?: string, isExported?: string, ordering?: ListInputRecordRowsOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_record_rows/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {PartialUpdateInputRecordRowOrderingEnum} [ordering] Ordering
         * @param {PartialInputRecordRow} [partialInputRecordRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputRecordRow: async (id: string, inputRecordId?: string, all?: string, isExported?: string, ordering?: PartialUpdateInputRecordRowOrderingEnum, partialInputRecordRow?: PartialInputRecordRow, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateInputRecordRow', 'id', id)
            const localVarPath = `/api/input_record_rows/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialInputRecordRow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rawInputRecordRow: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rawInputRecordRow', 'id', id)
            const localVarPath = `/api/input_record_rows/{id}/raw/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {RetrieveInputRecordRowOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputRecordRow: async (id: string, inputRecordId?: string, all?: string, isExported?: string, ordering?: RetrieveInputRecordRowOrderingEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveInputRecordRow', 'id', id)
            const localVarPath = `/api/input_record_rows/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {UpdateInputRecordRowOrderingEnum} [ordering] Ordering
         * @param {InputRecordRow} [inputRecordRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputRecordRow: async (id: string, inputRecordId?: string, all?: string, isExported?: string, ordering?: UpdateInputRecordRowOrderingEnum, inputRecordRow?: InputRecordRow, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInputRecordRow', 'id', id)
            const localVarPath = `/api/input_record_rows/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputRecordId !== undefined) {
                localVarQueryParameter['input_record_id'] = inputRecordId;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }

            if (isExported !== undefined) {
                localVarQueryParameter['is_exported'] = isExported;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecordRow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InputRecordRowsApi - functional programming interface
 * @export
 */
export const InputRecordRowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InputRecordRowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InputRecordRow} [inputRecordRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInputRecordRow(inputRecordRow?: InputRecordRow, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordRow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInputRecordRow(inputRecordRow, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordRowsApi.createInputRecordRow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {DestroyInputRecordRowOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyInputRecordRow(id: string, inputRecordId?: string, all?: string, isExported?: string, ordering?: DestroyInputRecordRowOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyInputRecordRow(id, inputRecordId, all, isExported, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordRowsApi.destroyInputRecordRow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {ListInputRecordRowsOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInputRecordRows(page?: number, inputRecordId?: string, all?: string, isExported?: string, ordering?: ListInputRecordRowsOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInputRecordRows200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInputRecordRows(page, inputRecordId, all, isExported, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordRowsApi.listInputRecordRows']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {PartialUpdateInputRecordRowOrderingEnum} [ordering] Ordering
         * @param {PartialInputRecordRow} [partialInputRecordRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateInputRecordRow(id: string, inputRecordId?: string, all?: string, isExported?: string, ordering?: PartialUpdateInputRecordRowOrderingEnum, partialInputRecordRow?: PartialInputRecordRow, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialInputRecordRow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateInputRecordRow(id, inputRecordId, all, isExported, ordering, partialInputRecordRow, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordRowsApi.partialUpdateInputRecordRow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rawInputRecordRow(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordRow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rawInputRecordRow(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordRowsApi.rawInputRecordRow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {RetrieveInputRecordRowOrderingEnum} [ordering] Ordering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveInputRecordRow(id: string, inputRecordId?: string, all?: string, isExported?: string, ordering?: RetrieveInputRecordRowOrderingEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordRow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveInputRecordRow(id, inputRecordId, all, isExported, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordRowsApi.retrieveInputRecordRow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [inputRecordId] input_record_id
         * @param {string} [all] all
         * @param {string} [isExported] is_exported
         * @param {UpdateInputRecordRowOrderingEnum} [ordering] Ordering
         * @param {InputRecordRow} [inputRecordRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInputRecordRow(id: string, inputRecordId?: string, all?: string, isExported?: string, ordering?: UpdateInputRecordRowOrderingEnum, inputRecordRow?: InputRecordRow, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordRow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInputRecordRow(id, inputRecordId, all, isExported, ordering, inputRecordRow, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordRowsApi.updateInputRecordRow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InputRecordRowsApi - factory interface
 * @export
 */
export const InputRecordRowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InputRecordRowsApiFp(configuration)
    return {
        /**
         * 
         * @param {InputRecordRowsApiCreateInputRecordRowRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputRecordRow(requestParameters: InputRecordRowsApiCreateInputRecordRowRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordRow> {
            return localVarFp.createInputRecordRow(requestParameters.inputRecordRow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordRowsApiDestroyInputRecordRowRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputRecordRow(requestParameters: InputRecordRowsApiDestroyInputRecordRowRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.destroyInputRecordRow(requestParameters.id, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordRowsApiListInputRecordRowsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputRecordRows(requestParameters: InputRecordRowsApiListInputRecordRowsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInputRecordRows200Response> {
            return localVarFp.listInputRecordRows(requestParameters.page, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordRowsApiPartialUpdateInputRecordRowRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputRecordRow(requestParameters: InputRecordRowsApiPartialUpdateInputRecordRowRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartialInputRecordRow> {
            return localVarFp.partialUpdateInputRecordRow(requestParameters.id, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, requestParameters.partialInputRecordRow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordRowsApiRawInputRecordRowRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rawInputRecordRow(requestParameters: InputRecordRowsApiRawInputRecordRowRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordRow> {
            return localVarFp.rawInputRecordRow(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordRowsApiRetrieveInputRecordRowRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputRecordRow(requestParameters: InputRecordRowsApiRetrieveInputRecordRowRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordRow> {
            return localVarFp.retrieveInputRecordRow(requestParameters.id, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordRowsApiUpdateInputRecordRowRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputRecordRow(requestParameters: InputRecordRowsApiUpdateInputRecordRowRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordRow> {
            return localVarFp.updateInputRecordRow(requestParameters.id, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, requestParameters.inputRecordRow, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInputRecordRow operation in InputRecordRowsApi.
 * @export
 * @interface InputRecordRowsApiCreateInputRecordRowRequest
 */
export interface InputRecordRowsApiCreateInputRecordRowRequest {
    /**
     * 
     * @type {InputRecordRow}
     * @memberof InputRecordRowsApiCreateInputRecordRow
     */
    readonly inputRecordRow?: InputRecordRow
}

/**
 * Request parameters for destroyInputRecordRow operation in InputRecordRowsApi.
 * @export
 * @interface InputRecordRowsApiDestroyInputRecordRowRequest
 */
export interface InputRecordRowsApiDestroyInputRecordRowRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordRowsApiDestroyInputRecordRow
     */
    readonly id: string

    /**
     * input_record_id
     * @type {string}
     * @memberof InputRecordRowsApiDestroyInputRecordRow
     */
    readonly inputRecordId?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordRowsApiDestroyInputRecordRow
     */
    readonly all?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordRowsApiDestroyInputRecordRow
     */
    readonly isExported?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordRowsApiDestroyInputRecordRow
     */
    readonly ordering?: DestroyInputRecordRowOrderingEnum
}

/**
 * Request parameters for listInputRecordRows operation in InputRecordRowsApi.
 * @export
 * @interface InputRecordRowsApiListInputRecordRowsRequest
 */
export interface InputRecordRowsApiListInputRecordRowsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof InputRecordRowsApiListInputRecordRows
     */
    readonly page?: number

    /**
     * input_record_id
     * @type {string}
     * @memberof InputRecordRowsApiListInputRecordRows
     */
    readonly inputRecordId?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordRowsApiListInputRecordRows
     */
    readonly all?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordRowsApiListInputRecordRows
     */
    readonly isExported?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordRowsApiListInputRecordRows
     */
    readonly ordering?: ListInputRecordRowsOrderingEnum
}

/**
 * Request parameters for partialUpdateInputRecordRow operation in InputRecordRowsApi.
 * @export
 * @interface InputRecordRowsApiPartialUpdateInputRecordRowRequest
 */
export interface InputRecordRowsApiPartialUpdateInputRecordRowRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordRowsApiPartialUpdateInputRecordRow
     */
    readonly id: string

    /**
     * input_record_id
     * @type {string}
     * @memberof InputRecordRowsApiPartialUpdateInputRecordRow
     */
    readonly inputRecordId?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordRowsApiPartialUpdateInputRecordRow
     */
    readonly all?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordRowsApiPartialUpdateInputRecordRow
     */
    readonly isExported?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordRowsApiPartialUpdateInputRecordRow
     */
    readonly ordering?: PartialUpdateInputRecordRowOrderingEnum

    /**
     * 
     * @type {PartialInputRecordRow}
     * @memberof InputRecordRowsApiPartialUpdateInputRecordRow
     */
    readonly partialInputRecordRow?: PartialInputRecordRow
}

/**
 * Request parameters for rawInputRecordRow operation in InputRecordRowsApi.
 * @export
 * @interface InputRecordRowsApiRawInputRecordRowRequest
 */
export interface InputRecordRowsApiRawInputRecordRowRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordRowsApiRawInputRecordRow
     */
    readonly id: string
}

/**
 * Request parameters for retrieveInputRecordRow operation in InputRecordRowsApi.
 * @export
 * @interface InputRecordRowsApiRetrieveInputRecordRowRequest
 */
export interface InputRecordRowsApiRetrieveInputRecordRowRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordRowsApiRetrieveInputRecordRow
     */
    readonly id: string

    /**
     * input_record_id
     * @type {string}
     * @memberof InputRecordRowsApiRetrieveInputRecordRow
     */
    readonly inputRecordId?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordRowsApiRetrieveInputRecordRow
     */
    readonly all?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordRowsApiRetrieveInputRecordRow
     */
    readonly isExported?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordRowsApiRetrieveInputRecordRow
     */
    readonly ordering?: RetrieveInputRecordRowOrderingEnum
}

/**
 * Request parameters for updateInputRecordRow operation in InputRecordRowsApi.
 * @export
 * @interface InputRecordRowsApiUpdateInputRecordRowRequest
 */
export interface InputRecordRowsApiUpdateInputRecordRowRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordRowsApiUpdateInputRecordRow
     */
    readonly id: string

    /**
     * input_record_id
     * @type {string}
     * @memberof InputRecordRowsApiUpdateInputRecordRow
     */
    readonly inputRecordId?: string

    /**
     * all
     * @type {string}
     * @memberof InputRecordRowsApiUpdateInputRecordRow
     */
    readonly all?: string

    /**
     * is_exported
     * @type {string}
     * @memberof InputRecordRowsApiUpdateInputRecordRow
     */
    readonly isExported?: string

    /**
     * Ordering
     * @type {'created' | '-created'}
     * @memberof InputRecordRowsApiUpdateInputRecordRow
     */
    readonly ordering?: UpdateInputRecordRowOrderingEnum

    /**
     * 
     * @type {InputRecordRow}
     * @memberof InputRecordRowsApiUpdateInputRecordRow
     */
    readonly inputRecordRow?: InputRecordRow
}

/**
 * InputRecordRowsApi - object-oriented interface
 * @export
 * @class InputRecordRowsApi
 * @extends {BaseAPI}
 */
export class InputRecordRowsApi extends BaseAPI {
    /**
     * 
     * @param {InputRecordRowsApiCreateInputRecordRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordRowsApi
     */
    public createInputRecordRow(requestParameters: InputRecordRowsApiCreateInputRecordRowRequest = {}, options?: RawAxiosRequestConfig) {
        return InputRecordRowsApiFp(this.configuration).createInputRecordRow(requestParameters.inputRecordRow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordRowsApiDestroyInputRecordRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordRowsApi
     */
    public destroyInputRecordRow(requestParameters: InputRecordRowsApiDestroyInputRecordRowRequest, options?: RawAxiosRequestConfig) {
        return InputRecordRowsApiFp(this.configuration).destroyInputRecordRow(requestParameters.id, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordRowsApiListInputRecordRowsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordRowsApi
     */
    public listInputRecordRows(requestParameters: InputRecordRowsApiListInputRecordRowsRequest = {}, options?: RawAxiosRequestConfig) {
        return InputRecordRowsApiFp(this.configuration).listInputRecordRows(requestParameters.page, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordRowsApiPartialUpdateInputRecordRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordRowsApi
     */
    public partialUpdateInputRecordRow(requestParameters: InputRecordRowsApiPartialUpdateInputRecordRowRequest, options?: RawAxiosRequestConfig) {
        return InputRecordRowsApiFp(this.configuration).partialUpdateInputRecordRow(requestParameters.id, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, requestParameters.partialInputRecordRow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordRowsApiRawInputRecordRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordRowsApi
     */
    public rawInputRecordRow(requestParameters: InputRecordRowsApiRawInputRecordRowRequest, options?: RawAxiosRequestConfig) {
        return InputRecordRowsApiFp(this.configuration).rawInputRecordRow(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordRowsApiRetrieveInputRecordRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordRowsApi
     */
    public retrieveInputRecordRow(requestParameters: InputRecordRowsApiRetrieveInputRecordRowRequest, options?: RawAxiosRequestConfig) {
        return InputRecordRowsApiFp(this.configuration).retrieveInputRecordRow(requestParameters.id, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordRowsApiUpdateInputRecordRowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordRowsApi
     */
    public updateInputRecordRow(requestParameters: InputRecordRowsApiUpdateInputRecordRowRequest, options?: RawAxiosRequestConfig) {
        return InputRecordRowsApiFp(this.configuration).updateInputRecordRow(requestParameters.id, requestParameters.inputRecordId, requestParameters.all, requestParameters.isExported, requestParameters.ordering, requestParameters.inputRecordRow, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DestroyInputRecordRowOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type DestroyInputRecordRowOrderingEnum = typeof DestroyInputRecordRowOrderingEnum[keyof typeof DestroyInputRecordRowOrderingEnum];
/**
 * @export
 */
export const ListInputRecordRowsOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type ListInputRecordRowsOrderingEnum = typeof ListInputRecordRowsOrderingEnum[keyof typeof ListInputRecordRowsOrderingEnum];
/**
 * @export
 */
export const PartialUpdateInputRecordRowOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type PartialUpdateInputRecordRowOrderingEnum = typeof PartialUpdateInputRecordRowOrderingEnum[keyof typeof PartialUpdateInputRecordRowOrderingEnum];
/**
 * @export
 */
export const RetrieveInputRecordRowOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type RetrieveInputRecordRowOrderingEnum = typeof RetrieveInputRecordRowOrderingEnum[keyof typeof RetrieveInputRecordRowOrderingEnum];
/**
 * @export
 */
export const UpdateInputRecordRowOrderingEnum = {
    Created: 'created',
    Created2: '-created'
} as const;
export type UpdateInputRecordRowOrderingEnum = typeof UpdateInputRecordRowOrderingEnum[keyof typeof UpdateInputRecordRowOrderingEnum];
