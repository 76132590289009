/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { InputRecordGroup } from '../models';
// @ts-ignore
import { ListInputRecordGroups200Response } from '../models';
// @ts-ignore
import { PartialInputRecordGroup } from '../models';
/**
 * InputRecordGroupsApi - axios parameter creator
 * @export
 */
export const InputRecordGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InputRecordGroup} [inputRecordGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputRecordGroup: async (inputRecordGroup?: InputRecordGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_record_groups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecordGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputRecordGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyInputRecordGroup', 'id', id)
            const localVarPath = `/api/input_record_groups/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInputRecordGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadInputRecordGroup', 'id', id)
            const localVarPath = `/api/input_record_groups/{id}/download/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputRecordGroups: async (page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/input_record_groups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialInputRecordGroup} [partialInputRecordGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputRecordGroup: async (id: string, partialInputRecordGroup?: PartialInputRecordGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateInputRecordGroup', 'id', id)
            const localVarPath = `/api/input_record_groups/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialInputRecordGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputRecordGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveInputRecordGroup', 'id', id)
            const localVarPath = `/api/input_record_groups/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retry the export of the tms order.
         * @param {string} id 
         * @param {InputRecordGroup} [inputRecordGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryInputRecordGroup: async (id: string, inputRecordGroup?: InputRecordGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retryInputRecordGroup', 'id', id)
            const localVarPath = `/api/input_record_groups/{id}/retry/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecordGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InputRecordGroup} [inputRecordGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputRecordGroup: async (id: string, inputRecordGroup?: InputRecordGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInputRecordGroup', 'id', id)
            const localVarPath = `/api/input_record_groups/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inputRecordGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InputRecordGroupsApi - functional programming interface
 * @export
 */
export const InputRecordGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InputRecordGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InputRecordGroup} [inputRecordGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInputRecordGroup(inputRecordGroup?: InputRecordGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInputRecordGroup(inputRecordGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordGroupsApi.createInputRecordGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyInputRecordGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyInputRecordGroup(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordGroupsApi.destroyInputRecordGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInputRecordGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInputRecordGroup(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordGroupsApi.downloadInputRecordGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInputRecordGroups(page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInputRecordGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInputRecordGroups(page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordGroupsApi.listInputRecordGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialInputRecordGroup} [partialInputRecordGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateInputRecordGroup(id: string, partialInputRecordGroup?: PartialInputRecordGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialInputRecordGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateInputRecordGroup(id, partialInputRecordGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordGroupsApi.partialUpdateInputRecordGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveInputRecordGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveInputRecordGroup(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordGroupsApi.retrieveInputRecordGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retry the export of the tms order.
         * @param {string} id 
         * @param {InputRecordGroup} [inputRecordGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryInputRecordGroup(id: string, inputRecordGroup?: InputRecordGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryInputRecordGroup(id, inputRecordGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordGroupsApi.retryInputRecordGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {InputRecordGroup} [inputRecordGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInputRecordGroup(id: string, inputRecordGroup?: InputRecordGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputRecordGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInputRecordGroup(id, inputRecordGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InputRecordGroupsApi.updateInputRecordGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InputRecordGroupsApi - factory interface
 * @export
 */
export const InputRecordGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InputRecordGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {InputRecordGroupsApiCreateInputRecordGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInputRecordGroup(requestParameters: InputRecordGroupsApiCreateInputRecordGroupRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordGroup> {
            return localVarFp.createInputRecordGroup(requestParameters.inputRecordGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordGroupsApiDestroyInputRecordGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyInputRecordGroup(requestParameters: InputRecordGroupsApiDestroyInputRecordGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.destroyInputRecordGroup(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordGroupsApiDownloadInputRecordGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInputRecordGroup(requestParameters: InputRecordGroupsApiDownloadInputRecordGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordGroup> {
            return localVarFp.downloadInputRecordGroup(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordGroupsApiListInputRecordGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInputRecordGroups(requestParameters: InputRecordGroupsApiListInputRecordGroupsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInputRecordGroups200Response> {
            return localVarFp.listInputRecordGroups(requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordGroupsApiPartialUpdateInputRecordGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateInputRecordGroup(requestParameters: InputRecordGroupsApiPartialUpdateInputRecordGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<PartialInputRecordGroup> {
            return localVarFp.partialUpdateInputRecordGroup(requestParameters.id, requestParameters.partialInputRecordGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordGroupsApiRetrieveInputRecordGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveInputRecordGroup(requestParameters: InputRecordGroupsApiRetrieveInputRecordGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordGroup> {
            return localVarFp.retrieveInputRecordGroup(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retry the export of the tms order.
         * @param {InputRecordGroupsApiRetryInputRecordGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryInputRecordGroup(requestParameters: InputRecordGroupsApiRetryInputRecordGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordGroup> {
            return localVarFp.retryInputRecordGroup(requestParameters.id, requestParameters.inputRecordGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InputRecordGroupsApiUpdateInputRecordGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInputRecordGroup(requestParameters: InputRecordGroupsApiUpdateInputRecordGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<InputRecordGroup> {
            return localVarFp.updateInputRecordGroup(requestParameters.id, requestParameters.inputRecordGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInputRecordGroup operation in InputRecordGroupsApi.
 * @export
 * @interface InputRecordGroupsApiCreateInputRecordGroupRequest
 */
export interface InputRecordGroupsApiCreateInputRecordGroupRequest {
    /**
     * 
     * @type {InputRecordGroup}
     * @memberof InputRecordGroupsApiCreateInputRecordGroup
     */
    readonly inputRecordGroup?: InputRecordGroup
}

/**
 * Request parameters for destroyInputRecordGroup operation in InputRecordGroupsApi.
 * @export
 * @interface InputRecordGroupsApiDestroyInputRecordGroupRequest
 */
export interface InputRecordGroupsApiDestroyInputRecordGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordGroupsApiDestroyInputRecordGroup
     */
    readonly id: string
}

/**
 * Request parameters for downloadInputRecordGroup operation in InputRecordGroupsApi.
 * @export
 * @interface InputRecordGroupsApiDownloadInputRecordGroupRequest
 */
export interface InputRecordGroupsApiDownloadInputRecordGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordGroupsApiDownloadInputRecordGroup
     */
    readonly id: string
}

/**
 * Request parameters for listInputRecordGroups operation in InputRecordGroupsApi.
 * @export
 * @interface InputRecordGroupsApiListInputRecordGroupsRequest
 */
export interface InputRecordGroupsApiListInputRecordGroupsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof InputRecordGroupsApiListInputRecordGroups
     */
    readonly page?: number
}

/**
 * Request parameters for partialUpdateInputRecordGroup operation in InputRecordGroupsApi.
 * @export
 * @interface InputRecordGroupsApiPartialUpdateInputRecordGroupRequest
 */
export interface InputRecordGroupsApiPartialUpdateInputRecordGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordGroupsApiPartialUpdateInputRecordGroup
     */
    readonly id: string

    /**
     * 
     * @type {PartialInputRecordGroup}
     * @memberof InputRecordGroupsApiPartialUpdateInputRecordGroup
     */
    readonly partialInputRecordGroup?: PartialInputRecordGroup
}

/**
 * Request parameters for retrieveInputRecordGroup operation in InputRecordGroupsApi.
 * @export
 * @interface InputRecordGroupsApiRetrieveInputRecordGroupRequest
 */
export interface InputRecordGroupsApiRetrieveInputRecordGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordGroupsApiRetrieveInputRecordGroup
     */
    readonly id: string
}

/**
 * Request parameters for retryInputRecordGroup operation in InputRecordGroupsApi.
 * @export
 * @interface InputRecordGroupsApiRetryInputRecordGroupRequest
 */
export interface InputRecordGroupsApiRetryInputRecordGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordGroupsApiRetryInputRecordGroup
     */
    readonly id: string

    /**
     * 
     * @type {InputRecordGroup}
     * @memberof InputRecordGroupsApiRetryInputRecordGroup
     */
    readonly inputRecordGroup?: InputRecordGroup
}

/**
 * Request parameters for updateInputRecordGroup operation in InputRecordGroupsApi.
 * @export
 * @interface InputRecordGroupsApiUpdateInputRecordGroupRequest
 */
export interface InputRecordGroupsApiUpdateInputRecordGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof InputRecordGroupsApiUpdateInputRecordGroup
     */
    readonly id: string

    /**
     * 
     * @type {InputRecordGroup}
     * @memberof InputRecordGroupsApiUpdateInputRecordGroup
     */
    readonly inputRecordGroup?: InputRecordGroup
}

/**
 * InputRecordGroupsApi - object-oriented interface
 * @export
 * @class InputRecordGroupsApi
 * @extends {BaseAPI}
 */
export class InputRecordGroupsApi extends BaseAPI {
    /**
     * 
     * @param {InputRecordGroupsApiCreateInputRecordGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordGroupsApi
     */
    public createInputRecordGroup(requestParameters: InputRecordGroupsApiCreateInputRecordGroupRequest = {}, options?: RawAxiosRequestConfig) {
        return InputRecordGroupsApiFp(this.configuration).createInputRecordGroup(requestParameters.inputRecordGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordGroupsApiDestroyInputRecordGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordGroupsApi
     */
    public destroyInputRecordGroup(requestParameters: InputRecordGroupsApiDestroyInputRecordGroupRequest, options?: RawAxiosRequestConfig) {
        return InputRecordGroupsApiFp(this.configuration).destroyInputRecordGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordGroupsApiDownloadInputRecordGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordGroupsApi
     */
    public downloadInputRecordGroup(requestParameters: InputRecordGroupsApiDownloadInputRecordGroupRequest, options?: RawAxiosRequestConfig) {
        return InputRecordGroupsApiFp(this.configuration).downloadInputRecordGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordGroupsApiListInputRecordGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordGroupsApi
     */
    public listInputRecordGroups(requestParameters: InputRecordGroupsApiListInputRecordGroupsRequest = {}, options?: RawAxiosRequestConfig) {
        return InputRecordGroupsApiFp(this.configuration).listInputRecordGroups(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordGroupsApiPartialUpdateInputRecordGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordGroupsApi
     */
    public partialUpdateInputRecordGroup(requestParameters: InputRecordGroupsApiPartialUpdateInputRecordGroupRequest, options?: RawAxiosRequestConfig) {
        return InputRecordGroupsApiFp(this.configuration).partialUpdateInputRecordGroup(requestParameters.id, requestParameters.partialInputRecordGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordGroupsApiRetrieveInputRecordGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordGroupsApi
     */
    public retrieveInputRecordGroup(requestParameters: InputRecordGroupsApiRetrieveInputRecordGroupRequest, options?: RawAxiosRequestConfig) {
        return InputRecordGroupsApiFp(this.configuration).retrieveInputRecordGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retry the export of the tms order.
     * @param {InputRecordGroupsApiRetryInputRecordGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordGroupsApi
     */
    public retryInputRecordGroup(requestParameters: InputRecordGroupsApiRetryInputRecordGroupRequest, options?: RawAxiosRequestConfig) {
        return InputRecordGroupsApiFp(this.configuration).retryInputRecordGroup(requestParameters.id, requestParameters.inputRecordGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InputRecordGroupsApiUpdateInputRecordGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InputRecordGroupsApi
     */
    public updateInputRecordGroup(requestParameters: InputRecordGroupsApiUpdateInputRecordGroupRequest, options?: RawAxiosRequestConfig) {
        return InputRecordGroupsApiFp(this.configuration).updateInputRecordGroup(requestParameters.id, requestParameters.inputRecordGroup, options).then((request) => request(this.axios, this.basePath));
    }
}

