import * as React from "react";
import { ITreeNode } from "../functions/xml";
import { Tree } from "antd";

interface IProps {
    tree: ITreeNode;
}

interface IBaseTreeNode {
    key: string;
    title: string;
    children?: IBaseTreeNode[];
}

export const XMLTreeView: React.FC<IProps> = (props) => {
    const parseNode = (node: ITreeNode, path = ""): IBaseTreeNode => {
        let title: string = `<${node.name}>`;

        if (node.children.length === 0) {
            title = `<${node.name}>${node.text}</${node.name}>`;
        }

        const newPath = path ? `${path}.${node.name}` : node.name;

        return {
            key: newPath,
            title,
            children: node.children.map((childNode, childIndex) =>
                parseNode(childNode, newPath)
            ),
        };
    };

    return (
        <Tree<IBaseTreeNode>
            defaultExpandAll={true}
            treeData={[parseNode(props.tree)]}
        />
    );
};
