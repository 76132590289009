import moment, { Moment } from "moment";

export const formatXml = (input: string): string => {
    return input.replaceAll(">", ">\n").replaceAll(">\n\n", ">\n");
};

/**
 * Convert seconds to a preformatted/ padded HH:mm:ss string
 * @param seconds
 */
export const secondsToHoursAndMinutesAndSecondsString = (
    seconds: number
): string => {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const diffBetweenMomentsAsString = (
    momentA: Moment,
    momentB: Moment
): string => {
    // Calculate time difference
    const timeDiff = momentB.diff(momentA);

    // Extract minutes, seconds, and milliseconds
    const duration = moment.duration(timeDiff);
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");
    const milliseconds = duration.milliseconds().toString().padStart(3, "0");

    // Create a string representation
    return `${minutes}:${seconds}:${milliseconds}`;
};
