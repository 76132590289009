import * as React from "react";
import { RawDataModal } from "./RawDataModal";

interface IProps {
    raw: string | object;
}

export const RawDataPreview: React.FC<IProps> = (props) => {
    const [rawPreview, setRawPreview] = React.useState(false);

    const value =
        typeof props.raw === "object" ? JSON.stringify(props.raw) : props.raw;

    return (
        <>
            {rawPreview && (
                <RawDataModal
                    data={props.raw}
                    onClose={() => setRawPreview(false)}
                />
            )}
            <span
                onClick={() => setRawPreview(true)}
                style={{ cursor: "pointer" }}
            >
                {value}
            </span>
        </>
    );
};
