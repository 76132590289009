export interface ITreeNode {
    name: string;
    text: string | null;
    attributes: { [key: string]: string };
    children: ITreeNode[];
}

export const parseXML = (xmlString: string): ITreeNode | null => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");

    if (!xmlDoc) {
        console.error("Failed to parse XML.");
        return null;
    }

    const rootElement = xmlDoc.documentElement;

    if (!rootElement) {
        console.error("XML document has no root element.");
        return null;
    }

    return parseElement(rootElement);
};

const parseElement = (element: Element): ITreeNode => {
    const node: ITreeNode = {
        name: element.nodeName,
        text: null,
        attributes: {},
        children: [],
    };

    // Parse attributes
    for (let i = 0; i < element.attributes.length; i++) {
        const attribute = element.attributes[i];
        node.attributes[attribute.name] = attribute.value;
    }

    // Parse child elements
    for (let i = 0; i < element.children.length; i++) {
        const childElement = element.children[i];
        const childNode = parseElement(childElement);
        node.children.push(childNode);
    }

    if (node.children.length === 0) {
        node.text = element.textContent;
    }

    return node;
};
