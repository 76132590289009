import * as React from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface IProps {
    loading: boolean;
    fetch: () => void;
}

export const RefetchButton: React.FC<IProps> = (props) => {
    return (
        <Button
            loading={props.loading}
            icon={
                <ReloadOutlined style={{ color: "black", cursor: "pointer" }} />
            }
            onClick={() => props.fetch()}
        />
    );
};
