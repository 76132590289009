import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { Modal } from "antd";

export const getAxios = () => {
    const client = axios.create();
    return applyCaseMiddleware(client);
};

// Intercept errors and show modal.
axios.interceptors.response.use(undefined, (error) => {
    if (!error) {
        return error;
    }
    if (!error.response) {
        return error;
    }

    // Don't show errors for the auth requests.
    if (
        error.request &&
        error.request.responseURL &&
        "/auth/" in error.request.responseURL
    ) {
        return error;
    }

    if (error.response.status === 400) {
        const data = error.response.data;
        let message = "";

        if (typeof data == "object") {
            message = JSON.stringify(data, null, 4);
        } else if (typeof data == "string") {
            message = data;
        }

        if (message) {
            Modal.error({
                title: <pre>{message}</pre>,
            });
        }
    } else {
        Modal.error({
            title: "Something went wrong!",
        });
    }
    return error;
});
