/* tslint:disable */
/* eslint-disable */
/**
 * Data Processor
 * NextUp Data processor
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@nextupsoftware.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AuthToken } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} username 
         * @param {string} password 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthToken: async (username: string, password: string, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('createAuthToken', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('createAuthToken', 'password', password)
            const localVarPath = `/api/auth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (username !== undefined) { 
                localVarFormParams.append('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.append('password', password as any);
            }
    
            if (token !== undefined) { 
                localVarFormParams.append('token', token as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogout: async (body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {string} password 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthToken(username: string, password: string, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthToken(username, password, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLogout(body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLogout(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createLogout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {AuthApiCreateAuthTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthToken(requestParameters: AuthApiCreateAuthTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuthToken> {
            return localVarFp.createAuthToken(requestParameters.username, requestParameters.password, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthApiCreateLogoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogout(requestParameters: AuthApiCreateLogoutRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createLogout(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAuthToken operation in AuthApi.
 * @export
 * @interface AuthApiCreateAuthTokenRequest
 */
export interface AuthApiCreateAuthTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiCreateAuthToken
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiCreateAuthToken
     */
    readonly password: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiCreateAuthToken
     */
    readonly token?: string
}

/**
 * Request parameters for createLogout operation in AuthApi.
 * @export
 * @interface AuthApiCreateLogoutRequest
 */
export interface AuthApiCreateLogoutRequest {
    /**
     * 
     * @type {any}
     * @memberof AuthApiCreateLogout
     */
    readonly body?: any
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {AuthApiCreateAuthTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthToken(requestParameters: AuthApiCreateAuthTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthToken(requestParameters.username, requestParameters.password, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthApiCreateLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createLogout(requestParameters: AuthApiCreateLogoutRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createLogout(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

