import * as React from "react";
import "./App.css";
import { Router } from "./router/Router";
import { AuthGuard } from "./auth/components/AuthGuard";
import { AuthenticatedMenu } from "./menu/AuthenticatedMenu";
import { GlobalContextProvider } from "./context/GlobalContextProvider";
import { ConfigProvider } from "antd";
import moment from "moment";

import nlNL from "antd/es/locale/nl_NL";
import "moment/locale/nl";

import "antd/dist/antd.min.css";
import { HotkeySwitch } from "./shared/components/HotkeySwitch";
import { GlobalFilterContextProvider } from "./shared/contexts/GlobalFilterContextProvider";

moment.locale("nl", {
    week: {
        dow: 1, // Monday is the first day of the week
    },
});

function App() {
    return (
        <div className="App">
            <GlobalContextProvider>
                <ConfigProvider locale={nlNL}>
                    <AuthGuard>
                        <>
                            <AuthenticatedMenu />
                            <HotkeySwitch />
                        </>
                    </AuthGuard>
                    <GlobalFilterContextProvider>
                        <Router />
                    </GlobalFilterContextProvider>
                </ConfigProvider>
            </GlobalContextProvider>
        </div>
    );
}

export default App;
