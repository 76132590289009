import * as React from "react";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

interface IProps {
    value: boolean | null;
}

const FONT_SIZE = "25px";

export const Status: React.FC<IProps> = (props) => {
    const renderIcon = () => {
        if (props.value === null) {
            return (
                <ExclamationCircleOutlined
                    style={{
                        color: "orange",
                        fontSize: FONT_SIZE,
                    }}
                />
            );
        }

        if (props.value) {
            return (
                <CheckCircleOutlined
                    style={{ color: "green", fontSize: FONT_SIZE }}
                />
            );
        }

        return (
            <CloseCircleOutlined
                style={{ color: "red", fontSize: FONT_SIZE }}
            />
        );
    };

    return <div style={{ textAlign: "center" }}>{renderIcon()}</div>;
};
